import useWindowSize from './use_window_size';

const BREAKPOINTS = [
  { name: 'phone', width: 768 },
  { name: 'tablet', width: 1200 },
  { name: 'desktop', width: 9999 },
];

// simple hook to tell if the screen is a phone, tablet, or desktop,
// based on the width of the screen
// the other approach would be to use css media queries
// e.g. window.matchMedia('(hover: none) and (pointer: coarse)')
// the advantage of this is that we can tell 'tablet' from 'phone'

export function getDeviceBreakpoint(width) {
  if (!width) {
    return BREAKPOINTS[0].name;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const breakpoint of BREAKPOINTS) {
    if (width < breakpoint.width) {
      return breakpoint.name;
    }
  }
  return BREAKPOINTS[BREAKPOINTS.length - 1].name;
}

export default function useDeviceBreakpoint() {
  const windowSize = useWindowSize();
  return getDeviceBreakpoint(windowSize.width);
}
