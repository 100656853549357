import { css } from '@emotion/react';

export default (outerSize = 42, innerSize = 27) => css`
  --main-color: #fba91a;
  --outer-size: ${outerSize}px;
  --inner-size: ${innerSize}px;
  --border-size: calc((var(--outer-size) - var(--inner-size)) / 2);

  font-size: 0;
  line-height: 0;
  display: inline-block;

  .star-border {
    display: inline-block;
    width: var(--outer-size);
    height: var(--outer-size);
    background-color: var(--main-color);
    clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
    );
    position: relative;
  }

  .star-body {
    display: inline-block;
    width: var(--inner-size);
    height: var(--inner-size);
    position: absolute;
    top: var(--border-size);
    left: var(--border-size);
    clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
    );
    background: white;
  }

  .star-filler {
    display: inline-block;
    background: var(--main-color);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
