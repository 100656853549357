import { css } from '@emotion/react';

export default css`
  margin-top: 8px;
  display: flex;
  align-items: center;
  font-family: OCRoboto, Roboto, sans-serif;

  @media (max-width: 768px) {
    margin-top: 16px;
  }

  .sort-by__label {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-wrap: nowrap;
    margin-right: 6px;
  }
`;
