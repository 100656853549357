import { useEffect, useState } from 'react';

const usePhotoViewerCarousel = ({
  carouselElement,
  initialPhotoSelected = 0,
}) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(
    initialPhotoSelected
  );

  useEffect(() => {
    carouselElement.current.scrollTo({
      left: carouselElement.current.clientWidth * initialPhotoSelected,
    });
    setCurrentPhotoIndex(initialPhotoSelected);
  }, [initialPhotoSelected, setCurrentPhotoIndex, carouselElement]);

  const scrollToNextPhoto = () => {
    const { scrollLeft, clientWidth } = carouselElement.current;
    carouselElement.current.scrollTo({
      left: scrollLeft + clientWidth,
      behavior: 'smooth',
    });
  };

  const scrollToPrevPhoto = () => {
    const { scrollLeft, clientWidth } = carouselElement.current;
    carouselElement.current.scrollTo({
      left: scrollLeft - clientWidth,
      behavior: 'smooth',
    });
  };

  const calculateCurrentPhotoIndex = () => {
    const { scrollLeft } = carouselElement.current;
    const photoIndex = Math.round(
      scrollLeft / carouselElement.current.clientWidth
    );
    setCurrentPhotoIndex(photoIndex);
  };

  return {
    currentPhotoIndex,
    scrollToNextPhoto,
    scrollToPrevPhoto,
    calculateCurrentPhotoIndex,
  };
};

export default usePhotoViewerCarousel;
