import styled from '@emotion/styled';

// Components
import Text from './utilities/Text';
import MediaObject from './MediaObject';
import Card from './Card';
import { H2 } from '../oc-h';

// Utilities
import { Tablet } from './internals/Devices';
import { Screens } from '../../PageBuilder/editing/Viewport';
import createSizes from "./utilities/sizes";

// Styled Components
const FeaturedListWrapper = styled.div`
  display: grid;
  gap: 30px;
  margin: ${props => (props.alignment == "center") ? "0 auto" : "0"};

  @media (min-width: ${Screens.Tablet}px) {
    grid-template-columns: ${props => (props.size === "full-bleed") ? '472px 236px' : 'calc(60% - 15px) calc(40% - 15px)'};
    ${({ size, width }) => size == 'full-bleed' ? `max-width: none` : `max-width: ${width.Tablet}px` };
  }

  @media (min-width: ${Screens.Desktop}px) {
    grid-template-columns: minmax(auto, 770px) minmax(auto, 370px);
    ${({ size, width }) => size == 'full-bleed' ? `max-width: none` : `max-width: ${width.Desktop}px` };
  }

  h2 {
    margin-bottom: 0;
  }

  > div {
    border-top: 2px solid black;
    padding-top: 32px;
  }
`

const Column1 = styled.div``;

const Column2 = styled.div``;

const MediaObjectLayout = styled.div`
  display: grid;
  gap: 32px;
`;

const Sizes = createSizes({ m: { [Tablet]: 608 } });


const CMSFeaturedList = ({ size = "xl", alignment = "center", copy, mediaObjects, card, settings }) => {
  const width = Sizes[size];

  return (
    <FeaturedListWrapper alignment={alignment} size={size} width={width}>
      <Column1>
        <MediaObjectLayout>
          <H2 font="body" tag="h4">{copy.heading}</H2>

          { mediaObjects?.map((mo) => (
            <MediaObject
              { ...mo }
              aspectRatio={ settings?.aspectRatio }
              imgSize="document"
            />
          ))}
        </MediaObjectLayout>
      </Column1>
      <Column2>
        <Card
          img={card.img?.file}
          crop={card.img?.crop}
          alt={card.img?.alt}
          prehead={card.prehead}
          heading={card.heading}
          text={card.text}
          linkText={card.linkText}
          url={card.url}
          aspectRatio={ settings?.aspectRatioCard }
        ></Card>
      </Column2>
    </FeaturedListWrapper>
  );
}

export default Text.Parse(
  CMSFeaturedList,
  'copy'
);
