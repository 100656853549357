import React from 'react';

import Styles from './styles';

const ContactUs = () => (
  <div css={Styles}>
    <div className="contact-us__layout">
      <h2 className="contact-us__title">Contact Us</h2>
      <div className="contact-us__content">
        Even though we’re a global company, we’re always ready to answer your
        questions. Call to speak with an Owens Corning representative.
      </div>
      <div className="contact-us__phone-number">1-800-GET-PINK</div>
    </div>
  </div>
);

export { ContactUs };
