import PropTypes from 'prop-types';
import { useState } from 'react';

import Styles from './styles';
import { PhotoViewerModal } from '../PhotoViewerModal';
import { RatingStarLine } from '../RatingStarLine';
import CheckCircle from '../icons/check-circle.svg';
import CheckShield from '../icons/check-shield.svg';
import ErrorCircle from '../icons/error-circle.svg';
// import ThumbsDown from '../icons/thumbs-down.svg';
// import ThumbsUp from '../icons/thumbs-up.svg';

const ReviewContent = ({
  id,
  ratings,
  verifiedPurchase,
  posterNickname,
  postersZipCode,
  productName = null,
  productColor,
  reviewTitle,
  reviewText,
  reviewDate,
  posterImages,
  bvIsRecommended,
  // thumbsUp,
  // thumbsDown,
}) => {
  const [openPhotoViewerModal, setOpenPhotoViewerModal] = useState(false);
  const [photoSelected, setPhotoSelected] = useState(0);

  const openPhotoViewerModalHandler = photoIndex => {
    setPhotoSelected(photoIndex);
    setOpenPhotoViewerModal(true);
  };

  return (
    <div css={Styles}>
      <div className="review-content__layout">
        <div className="review-content__sidebar">
          <div className="review-content__sidebar-nickname">
            {posterNickname}
          </div>
          <div className="review-content__sidebar-date-verified">
            <div className="review-content__sidebar-date">{reviewDate}</div>
            {verifiedPurchase && (
              <div className="review-content__sidebar-verified">
                <img src={CheckShield} alt="Verified Icon" /> Verified purchase
              </div>
            )}
          </div>
        </div>

        <div className="review-content__main">
          <div className="review-content__main-rating">
            <RatingStarLine
              percent={(ratings / 5) * 100}
              outerSize={17}
              innerSize={10}
            />
          </div>
          <div className="review-content__main-product-name">{reviewTitle}</div>
          <div className="review-content__main-product-attributes">
            <span className="review-content__main-product-attribute">
              <b>Product</b>: {productName}
            </span>
            {productColor && (
              <>
                <span className="review-content__main-product-attribute-delimiter">
                  |
                </span>
                <span className="review-content__main-product-attribute">
                  <b>Color</b>: {productColor}
                </span>
              </>
            )}
          </div>
          {postersZipCode && (
            <div className="review-content__main-zip-code">
              <b>Location</b>: {postersZipCode}
            </div>
          )}
          <div className="review-content__main-review-text">{reviewText}</div>
          {posterImages.length > 0 && (
            <div className="review-content__main-photos">
              {posterImages.map((image, index) => (
                <button
                  key={`${id}-${image.id}`}
                  type="button"
                  className="review-content__main-photos-button"
                  onClick={() => openPhotoViewerModalHandler(index)}
                >
                  <img
                    src={image.thumbnail}
                    alt={image.caption || "Poster's photography"}
                    data-testid="review-image"
                    className="review-content__main-photos-image"
                  />
                </button>
              ))}
            </div>
          )}
          <div className="review-content__main-recommend-checkbox">
            {bvIsRecommended && (
              <>
                <img src={CheckCircle} alt="Recommendation Icon" />
                Yes, I recommend this product.
              </>
            )}
            {bvIsRecommended === false && (
              <>
                <img src={ErrorCircle} alt="Recommendation Icon" />
                No, I do not recommend this product.
              </>
            )}
          </div>

          {/* <div
            className="review-content__main-helpful-vote"
            data-testid="review-vote"
          >
            Helpful?
            <div />
            <img src={ThumbsUp} alt="ThumbsUp" /> ({thumbsUp})
            <div />
            <img
              src={ThumbsDown}
              alt="ThumbsDown"
              data-testid="thumbs-down"
            />{' '}
            ({thumbsDown})
          </div> */}
        </div>
      </div>
      {openPhotoViewerModal && (
        <PhotoViewerModal
          open={openPhotoViewerModal}
          onClose={() => setOpenPhotoViewerModal(false)}
          posterImages={posterImages}
          ratings={ratings}
          reviewTitle={reviewTitle}
          productName={productName}
          productColor={productColor}
          postersZipCode={postersZipCode}
          posterNickname={posterNickname}
          reviewDate={reviewDate}
          reviewText={reviewText}
          initialPhotoSelected={photoSelected}
        />
      )}
    </div>
  );
};

ReviewContent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  ratings: PropTypes.number.isRequired,
  verifiedPurchase: PropTypes.bool.isRequired,
  posterNickname: PropTypes.string.isRequired,
  postersZipCode: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  productName: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  productColor: PropTypes.string.isRequired,
  reviewDate: PropTypes.string.isRequired,
  reviewTitle: PropTypes.string.isRequired,
  reviewText: PropTypes.string.isRequired,
  posterImages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      thumbnail: PropTypes.string.isRequired,
      caption: PropTypes.string,
    })
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  bvIsRecommended: PropTypes.oneOfType([PropTypes.bool, null]),
  // thumbsUp: PropTypes.number.isRequired,
  // thumbsDown: PropTypes.number.isRequired,
};

export { ReviewContent };
