const joinElements = (elements, separator) => {
  const joined = [];
  const filteredElements = elements?.filter(Boolean) || [];
  filteredElements.forEach((el, index) => {
    joined.push(el);
    if (index < filteredElements.length - 1) {
      joined.push(separator);
    }
  });
  return joined;
};

export default joinElements;
