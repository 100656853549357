import { css } from '@emotion/react';
import styled from '@emotion/styled'
import _ from 'lodash';

// Utilities
import { Mobile, Tablet, Desktop } from './internals/Devices';
import { Screens } from '../../PageBuilder/editing/Viewport';
import Gutter from './internals/Gutter';

// Components
import Video from '../oc-video';
import CloudinaryVideo from "../oc-cloudinary-video";
import Picture from '../oc-picture';
import RichText from './RichText';
import Link from '../oc-link';
import Linking from '../../PageBuilder/helpers/linking';
import ImageShareLinks from '../oc-image-share-links';
import createSizes from "./utilities/sizes";

const Sizes = createSizes({}, {
  'full-bleed': { [Mobile]: 405, [Tablet]: 798, [Desktop]: 1340 },
  's': { [Mobile]: 165, [Tablet]: 354, [Desktop]: 370 },
  'thm': { [Mobile]: 105, [Tablet]: 226, [Desktop]: 170 }
});

const Container = styled.div`
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Figure = styled.figure`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ width }) => `${width.Mobile}px`};

  @media (min-width: ${Screens.Tablet}px) {
    width: ${({ width }) => `${width.Tablet}px`};
  }

  @media (min-width: ${Screens.Desktop}px) {
    ${({ size, width, gutter }) => size == 'full-bleed' ? `width: 100%` : `width: ${width.Desktop}px` }
  }

  picture, img {
    display: block;
    width: 100%;
  }

  figcaption {
    display: inline-block;
    font-size: 16px;
    margin-top: 8px;
    text-align: ${props => props.captionAlignment || 'left'};

    @media (max-width: ${Screens.Tablet - 1}px) {
      ${({ size, width, gutter }) => size == 'full-bleed' && `width: ${width.Mobile - (gutter.Mobile * 2)}px;`}
      ${({ size, width }) => size == 's' && `width: ${width.Mobile + 60}px;`}
      ${({ size, width }) => size == 'thm' && `width: ${width.Mobile + 120}px;`}
    }

    @media (min-width: ${Screens.Tablet}px) {
      ${({ size, width, gutter }) => size == 'full-bleed' && `width: ${width.Tablet - (gutter.Tablet * 2)}px;`}
      ${({ size, width }) => size == 'thm' && `width: ${width.Tablet + 128}px;`}
    }

    @media (min-width: ${Screens.Desktop}px) {
      ${({ size, width }) => size == 'thm' && `width: ${width.Desktop + 200}px;`}
    }

    @media (min-width: ${Screens.Desktop}px) {
      ${({ size, width, gutter }) => size == 'full-bleed' ? `width: 100%` : `width: ${width.Desktop}px`}
    }
  }
`;

const PictureContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const CMSFigure = ({
  contentType,
  imageUrl,
  media: { type = 'image', resources: { image, video, youtube, caption, captionAlignment } = {} } = {},
  size = 'full-bleed',
  socialShare
}) => {
  const Width = Sizes[size];
  const imageLink = Linking.Parse(imageUrl);
  const transform = image?.crop ? { crop: 'crop', ...image.crop } : null;
  const captionValue = (type === 'image') ? image.caption : caption;
  const captionAlignmentValue = (type === 'image') ? image.captionAlignment : captionAlignment;

  return (
    <Container>
      {_.isEmpty(imageUrl) ?
        <Figure
          captionAlignment={captionAlignmentValue}
          gutter={Gutter(contentType)}
          size={size}
          width={Width}
        >
          {type == 'image' ?
            <PictureContainer>
              <Picture
                source={image?.file}
                alt={image?.alt}
                transform={ transform ? transform : {
                  //width: Width.Mobile,
                  crop: "fill",
                  quality: 50
                }}
                variants={[
                  {
                    media: `(min-width: ${Screens.Desktop}px) and (min-resolution: 2dppx)`,
                    width: Width.Desktop * 2,
                    quality: 30
                  },
                  {
                    media: `(min-width: ${Screens.Desktop}px)`,
                    width: Width.Desktop,
                    quality: 50
                  },
                  {
                    media: `(min-width: ${Screens.Tablet}px) and (min-resolution: 2dppx)`,
                    width: Width.Tablet * 2,
                    quality: 30
                  },
                  {
                    media: `(min-width: ${Screens.Tablet}px)`,
                    width: Width.Tablet,
                    quality: 50
                  },
                  {
                    width: Width.Mobile * 2,
                    quality: 30
                  },
                ]}
              />

              {socialShare && <ImageShareLinks imageUrl={image?.file} imageSize={size} />}
            </PictureContainer>
          :
            type == 'video'
              ? <CloudinaryVideo src={video?.url} />
              : <div css={css`width: 100%;`}>
                  <Video src={youtube?.url} />
                </div>
          }
          <figcaption>
            <RichText content={captionValue} />
          </figcaption>
        </Figure>
      :
        <Figure
          size={size}
          width={Width}
          gutter={Gutter(contentType)}
        >
          {
            type == 'image'
              ? <Link
                  href={imageLink.url}
                  target="_blank"
                >
                <Picture
                  source={image?.file}
                  alt={image?.alt}
                  transform={{
                    //width: Width.Mobile,
                    crop: "fill",
                    quality: 50
                  }}
                  variants={[
                    {
                      media: `(min-width: ${Screens.Desktop}px) and (min-resolution: 2dppx)`,
                      width: Width.Desktop * 2,
                      quality: 30
                    },
                    {
                      media: `(min-width: ${Screens.Desktop}px)`,
                      width: Width.Desktop,
                      quality: 50
                    },
                    {
                      media: `(min-width: ${Screens.Tablet}px) and (min-resolution: 2dppx)`,
                      width: Width.Tablet * 2,
                      quality: 30
                    },
                    {
                      media: `(min-width: ${Screens.Tablet}px)`,
                      width: Width.Tablet,
                      quality: 50
                    },
                    {
                      width: Width.Mobile * 2,
                      quality: 30
                    },
                  ]}
                />
              </Link>
              :
                type == 'video'
                  ? <CloudinaryVideo src={video?.url} />
                  : <div css={css` width: 100%; `} >
                      <Video src={youtube?.url} />
                    </div>
          }
          <figcaption>
            <RichText content={caption} />
          </figcaption>
        </Figure>
      }
    </Container>
  )
}

export default CMSFigure;
