import { define, ui } from '@owenscorning/pcb.alpha';

import RValueCalculator from '../../../../OC/PageBuilder/RValueCalculator';

export default define`R-Value Calculator`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/RValueCalculator`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: RValueCalculator,
  edit: {
    content: ui`Form`.of({
      heading: ui`Content/Basic`,
    })({ label: 'Calculator Settings' }),
  },
});
