import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import Styles from './styles';
import ChevronDown from '../icons/chevron-down.svg';
import ChevronUp from '../icons/chevron-up.svg';

const TABLET_BREAKPOINT = 768;
const displayValue = visible => (visible ? 'block' : 'none');

const ToggleSwitcher = ({ visible = true, toggleVisible }) => (
  <div
    className="authentic-review__toggle-switcher"
    data-testid="toggle-switcher"
  >
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
    <div
      className="authentic-review__toggle-switcher__more"
      style={{ display: displayValue(!visible) }}
      onClick={toggleVisible}
    >
      Read more about reviews
      <img
        src={ChevronDown}
        alt="Chevron Down"
        className="authentic-review__toggle-switcher__img"
      />
    </div>

    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
    <div
      className="authentic-review__toggle-switcher__less"
      style={{ display: displayValue(visible) }}
      onClick={toggleVisible}
    >
      Show less
      <img
        src={ChevronUp}
        alt="Chevron Up"
        className="authentic-review__toggle-switcher__img"
      />
    </div>
  </div>
);

ToggleSwitcher.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggleVisible: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/prop-types
const AuthenticReview = ({ preheader, header, precontent, content }) => {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => setVisible(!visible);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const desktopView = windowWidth > TABLET_BREAKPOINT;

  const handleResize = throttle(() => {
    setWindowWidth(window.innerWidth);
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div css={Styles}>
      <h3 className="authentic-review__header authentic-review__preheader">
        {preheader}
      </h3>

      <h3 className="authentic-review__header authentic-review__main_header">
        {header}
      </h3>

      <div
        className="authentic-review__precontent"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: precontent }}
      />

      <div
        className="authentic-review__content"
        style={{ display: displayValue(desktopView ? true : visible) }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
      />

      {!desktopView && (
        <ToggleSwitcher visible={visible} toggleVisible={toggleVisible} />
      )}
    </div>
  );
};

export { AuthenticReview };
