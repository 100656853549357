/* eslint-disable react/prop-types */
import styled from '@emotion/styled';
import React from 'react';

import { connectLocation } from '../../../location/LocationConnectors';
import ChevronLink from '../../oc-chevron-link';
import Picture from '../../oc-picture';

const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  > div:first-of-type {
    width: calc(10% + 32px);
  }

  img {
    max-width: 300px;
    width: -webkit-fill-available;
  }

  .preHeading {
    margin-bottom: 5px;
  }

  a {
    font-size: 18px;
  }
`;

function makeUrl(route, code) {
  return `/${code.toLowerCase()}${route}`;
}

const ProductResult = ({
  route,
  description,
  image,
  name,
  prehead,
  locale: { code },
}) => (
  <ProductContainer>
    <div>
      <Picture source={image} alt={name} />
    </div>
    <div>
      <div className="preHeading">{prehead}</div>
      <h3>{name}</h3>
      <p>{description}</p>
      <ChevronLink
        url={makeUrl(route, code)}
        data-track="view-product"
        data-track-product-name={name}
      >
        Product Details
      </ChevronLink>
    </div>
  </ProductContainer>
);

export default connectLocation(ProductResult);
