import { define, ui } from '@owenscorning/pcb.alpha';
import Renderer from './../../../../OC/PageBuilder/News.jsx';

export default define`News`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/News`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    newsHeading: ui`Form`.of({
      display: ui`Switch`({
        label: "Show News Heading",
        default: true
      }),
      text: ui`Text`({
        label: "Heading Text",
        default: "Recent News"
      })
    })({
      label: "News Heading"
    }),
    customCta: ui`Form`.of({
      cta: ui`Switch`({ label: 'Show/Hide Custom Cta', default: true }),
      ctaUrl: ui`Text`({ label: 'Custom Cta Url' }),
      ctaText: ui`Text`({ label: 'Custom Cta Text' })
    })({
      label: "Custom CTA"
    }),
    news: ui`List`.of({
      prehead: ui`Text`({ label: 'Prehead' }),
      heading: ui`Text`({ label: 'Heading' }),
      text: ui`Text/Rich`({ label: 'Text' }),
      cta: ui`CTA`({ label: false })
    })({
      min: 1, max: 3,
      singular: 'News Item',
      title: 'heading',
      label: 'News Items'
    }),
    containerSettings: ui`Form`.of({
      size: ui`Choices`.of({
        'xl': 'XL',
        'l': 'Large',
        'm': 'Medium',
      })({
        label: 'Size',
        default: 'xl',
        mode: ui`Choices/Mode/Dropdown`
      }),
      alignment: ui`Choices`.of(['Left', 'Center'])({
        label: 'Alignment',
        default: 'Left'
      }),
    })({
      label: "Container"
    }),
  },
});
