// image: app/javascript/components/Builder/Library/Modules/Page/Thumbnails/BVRatings.png
import { define, ui } from '@owenscorning/pcb.alpha';

import { ProductReviewLanding } from '../../../../Bazaarvoice/ProductReviewLanding';

export default define`BVRatings`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/BVRatings`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  // eslint-disable-next-line react/jsx-props-no-spreading
  view: ({ value }) => <ProductReviewLanding {...value} />,
  edit: {},
});
