import Label from '../../../ComponentLibrary/input-elements/oc-label';
import RadioButton from '../../../ComponentLibrary/input-elements/oc-radio-button';

const RadioGroup = ({ label, name, options, onChange, value }) => {
  if (!name && !label) {
    throw new Error('RadioGroup requires a name or label');
  }
  // eslint-disable-next-line no-param-reassign
  name = name || label.toLowerCase().replace(' ', '_');
  return (
    <div className="radioGroup">
      <Label text={label} name={name} />
      {options.map(option => (
        <RadioButton
          key={option.value}
          name={name}
          value={option.value}
          checked={value === option.value}
          onChange={onChange}
          disabled={option.disabled}
        >
          {option.text}
        </RadioButton>
      ))}
    </div>
  );
};

export default RadioGroup;
