import { css } from '@emotion/react';

export default css`
  display: flex;
  justify-content: center;

  .more-review-content-button {
    color: #fff;
    text-align: center;

    font-family: OCRoboto, Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-transform: capitalize;

    padding: 6px 16px;
    border: 1px solid #fff;
    background: #000;
    cursor: pointer;
  }

  .more-review-content-button:hover {
    background: #d40f7d;
  }
`;
