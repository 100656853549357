import {combineReducers, createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {byrEditorReducer} from "./BuildYourRoof/CustomizeConnectors";
import {byrConfigReducer} from './BuildYourRoof/BYRConnectors';
import {shinglesFavoritesReducer} from './Roofing/Shingles/Favorites/FavoritesConnectors';
import {shinglesRecentlyViewedReducer} from './Roofing/Shingles/Favorites/RecentlyViewedConnectors';
import {fiberglassCalculatorReducer} from './ProPink/FiberglassConnectors'
import {celluloseCalculatorReducer} from './ProPink/CelluloseConnectors'
import { enclosureReducer } from './wallbuilder/connector';
import {productsCompatibilityReducer} from './Roofing/ProductsCompatibility';
import {locationReducer} from './location/LocationConnectors';
import {genericLocatorReducer} from "./OC/GenericLocator/GenericLocatorConnectors";
import {sprayfoamReducer} from './ProPink/SprayfoamConnectors';
import {videoMetadataReducer} from './VideoMetadata/VideoMetadataConnector';
import {featureFlagReducer} from './FeatureFlag/FeatureFlagConnectors';
import { findAContractorReducer } from '../redux/connectors/FindAContractorConnector'
import subscriptions from './Subscriptions';
import { composeWithDevTools } from 'redux-devtools-extension';
import styleBoardReducer from '../redux/reducers/StyleBoardReducer';
import URLSearchParamSubscription from '../redux/subscriptions/URLSearchParamSubscription';
import URLSearchParamReducer from '../redux/reducers/URLSearchParamReducer';

export const KEY = 'root';

export const REDUX_PERSIST_PREFIX = 'persist:';

const nullStorage = {
  setItem: function() {
    return Promise.resolve();
  },
  getItem: function() {
    return Promise.resolve();
  },
  removeItem: function() {
    return Promise.resolve();
  }
};

const persistConfig = {
  key: KEY,
  storage: typeof(window) !== 'undefined' ? storage : nullStorage,
  whitelist: ['shinglesFavorites', 'shinglesRecentlyViewed']
}

const persistedReducers = persistReducer(
  persistConfig,
  combineReducers({
    byrConfig: byrConfigReducer,
    byrEditor: byrEditorReducer,
    productsCompatibility: productsCompatibilityReducer,
    shinglesFavorites: shinglesFavoritesReducer,
    shinglesRecentlyViewed: shinglesRecentlyViewedReducer,
    fiberglassCalculator: fiberglassCalculatorReducer,
    findAContractor: findAContractorReducer,
    celluloseCalculator: celluloseCalculatorReducer,
    sprayfoamCalculator: sprayfoamReducer,
    enclosure: enclosureReducer,
    location: locationReducer,
    urlParams: URLSearchParamReducer,
    genericLocator: genericLocatorReducer,
    videoMetadata: videoMetadataReducer,
    featureFlag: featureFlagReducer,
    styleBoard: styleBoardReducer,
  })
);
export const store = createStore(
  persistedReducers,
  composeWithDevTools(
    applyMiddleware(thunk)
  )
);

const unsubscribeDefaultSubscriptions = subscriptions(store);
const unsubscribeUrlSubscriptions = URLSearchParamSubscription(store)

const unsubscribe = () => {
  unsubscribeDefaultSubscriptions();
  unsubscribeUrlSubscriptions();
}

store.unsubscribeAll = unsubscribe;

export const persistor = persistStore(store);
