import { useRef, useState } from "react";
import { BasicContent } from './BasicContent';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Components
import AttentionBarText from '../oc-attention-bar-text';
import AttentionBarWithImage from '../oc-attention-bar-with-image';
import AttentionBarWithColumns from '../oc-attention-bar-with-columns-insulation';
import AttentionBarRoofing from '../oc-attention-bar-roofing';
import AttentionBarButtons from '../oc-attention-bar-buttons';
import Cta from './Cta';

// Utilities
import { Mobile, Tablet, Desktop } from './internals/Devices';
import { Screens } from '../../PageBuilder/editing/Viewport';
import createSizes from "./utilities/sizes";

// Styled Components
const Container = styled.div`
  display: block;
  margin: ${props => (props.alignment == "center") ? "0 auto" : "0"};
  position: relative;
  width: 100%;

  @media (min-width: ${Screens.Tablet}px) {
    ${({ size, width }) => (size == 'full-bleed') ? `max-width: none` : `max-width: ${width.Tablet}px` };
  }

  @media (min-width: ${Screens.Desktop}px) {
    ${({ size, width }) => (size == 'full-bleed') ? `max-width: none` : `max-width: ${width.Desktop}px` };
  }
`;

const AttentionBarWrapper = styled.div`
  .oc-attention-bar {
    width: 100% !important;
    margin: 0 !important;
  }
`;

const CTAContainer = styled.div`
  &:first-child a {
    color: ${ (props) => props.color === 'borderless' && 'white !important' }
  }
`;

const MultiCTAContainer = styled.div`
  > a {
    color: ${ (props) => props.color === 'borderless' && 'white !important' }
  }
`;

const Close = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`;

// hacky css, but UI.Module doesn't depend on its child. so this was the easy way to achieve full bleed
const FullBleedExpander = styled.div`${() => css`
  margin: 0 -${ Board.Gutter.Desktop }px;
  @media (${ UI.Viewport.to.Desktop }) { margin: 0 -${ Board.Gutter.Tablet }px; }
  @media (${ UI.Viewport.to.Tablet }) { margin: 0 -${ Board.Gutter.Mobile }px; }
`}`;

const Sizes = createSizes({ m: { [Tablet]: 608 } },
  { 'full-bleed': { [Mobile]: 405, [Tablet]: 798, [Desktop]: 1340 } }
);

const AttentionBarPicker = ({
  __framed,
  settings = {},
  layoutWithImage,
  contentWithImage,
  imageWithImage,

  layout = 'custom',
  copy,
  heading,
  cta,
  ctas,

  ...props
}) => {
  const [showHide, setShowHide] = useState('show');
  //const [cookies, setCookie] = useCookies(["attentionBarHidden"]);
  const alignment = settings.alignment || "center";
  const size = settings.size || "xl";
  const width = Sizes[size];

  const CloseButton = () => {
    const closeHandler = () => {
      if (!__framed) setShowHide('hide');
      //setCookie("attentionBarHidden", "true", { path: '/' });
    }

    return (
      <Close
        aria-label="close"
        onClick={() => { closeHandler() }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7288 3.27465L11.0052 8L15.7288 12.7253C16.0827 13.0794 16.0827 13.6566 15.7288 14.0183L14.0132 15.7345C13.6593 16.0885 13.0823 16.0885 12.7208 15.7345L7.99711 11.0091L3.27347 15.7345C2.91958 16.0885 2.34259 16.0885 1.98101 15.7345L0.265417 14.0183C-0.0884722 13.6643 -0.0884722 13.0871 0.265417 12.7253L4.98906 8L0.265417 3.27465C-0.0884722 2.92063 -0.0884722 2.34343 0.265417 1.98172L1.98101 0.265512C2.3349 -0.0885041 2.91189 -0.0885041 3.27347 0.265512L7.99711 4.99086L12.7208 0.265512C13.0746 -0.0885041 13.6516 -0.0885041 14.0132 0.265512L15.7288 1.98172C16.0904 2.33574 16.0904 2.91294 15.7288 3.27465Z"
            fill="white"
          />
        </svg>
      </Close>
    );
  };

  const Wrapper = (size == "full-bleed") ? FullBleedExpander : Container;
  const Content = () => {
    const animationProps = {
      animate: (settings.animate || false),
      flashAnimRef: useRef(null),
      slideAnimRef: useRef(null)
    };

    // If showHide state is set to 'hide', return null
    if (showHide !== 'show') return null;

    // If attentionBarType is withImage, return AttentionBarWithImage
    if (settings.attentionBarType === 'withImage') {
      return (
        <AttentionBarWithImage
          ratio={ layoutWithImage?.ratio }
          img={ imageWithImage?.img || imageWithImage?.file }
          crop={ imageWithImage?.crop }
          alt={ imageWithImage?.alt }
          className="relative"
          animationProps={animationProps}
          { ...props }
        >
          { settings.closeButton && <CloseButton /> }
          <BasicContent { ...contentWithImage } slideAnimRef={animationProps.slideAnimRef}/>
        </AttentionBarWithImage>
      );
    }

    // If custom layout
    if (layout == 'custom') {
      return (
        <AttentionBarWrapper data-am-region="Attention Bar">
          <AttentionBarText
            prehead={ copy?.prehead }
            heading={ copy?.heading }
            text={ copy?.content }
            noMargin
            className="relative"
            animationProps={animationProps}
          >
            { settings.closeButton && <CloseButton /> }
            <CTAContainer color={ cta?.color }>
              <Cta { ...cta } styles={{ paddingTop: '0px' }}/>
            </CTAContainer>
          </AttentionBarText>
        </AttentionBarWrapper>
      );
    }

    // If multi-CTA layout
    if (layout == 'multiCTA') {
      return (
        <AttentionBarButtons heading={ copy?.heading } noMargin ctas={ctas} animationProps={animationProps}>
          {(ctas || []).map(cta => (
            <MultiCTAContainer className='multi-cta-container' color={ cta?.color }>
              <Cta { ...cta } standalone />
            </MultiCTAContainer>
          ))}
        </AttentionBarButtons>
      );
    }

    // If roofing layout
    if (layout == 'roofing') {
      return (<AttentionBarRoofing animationProps={animationProps} />);
    }

    // If none of the above conditions are met, return column attention bar
    return (
      <AttentionBarWithColumns layout={layout} noMargin animationProps={animationProps} />
    );
  };

  return (
    <Wrapper alignment={alignment} size={size} width={width}>
      <Content />
    </Wrapper>
  );
};

export default AttentionBarPicker;
