import PropTypes from 'prop-types';
import { useState } from 'react';

import Styles from './styles';
import { formatTotal, inPercent } from './utils';
import { RatingStarLine } from '../RatingStarLine';
import { RatingTable } from '../RatingTable';
import { ReviewModal } from '../ReviewModal';

// Calculate total reviews
const totalReviews = starRating =>
  Object.keys(starRating).reduce((acc, key) => acc + starRating[key], 0);

const Rating = ({
  ratingAverageValue = 0,
  starRating = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  },
}) => {
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const reviewModalShow = isOpen => () => setReviewModalVisible(isOpen);

  return (
    <div css={Styles}>
      <div className="rating-layout">
        <h3 className="rating-title">Overall Rating</h3>

        <div className="rating-section">
          <div className="rating-section_data">
            <div className="rating-values">
              <div className="rating-average-value">{ratingAverageValue}</div>
              <div>
                <RatingStarLine percent={inPercent(ratingAverageValue)} />
              </div>
            </div>

            <RatingTable
              starRating={starRating}
              totalReviews={totalReviews(starRating)}
            />

            <div className="rating-average-description">
              Average rating based on {formatTotal(totalReviews(starRating))}{' '}
              reviews
            </div>
          </div>

          <div className="rating-section_delimiter" />

          <div className="rating-share">
            <p className="rating-share-description">
              Share your opinion with other Owens Corning customers
            </p>

            <div>
              <button
                type="button"
                className="rating-button"
                onClick={reviewModalShow(true)}
              >
                Write a Review
              </button>
              {reviewModalVisible && (
                <ReviewModal onClose={reviewModalShow(false)} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Rating.propTypes = {
  ratingAverageValue: PropTypes.number.isRequired,
  starRating: PropTypes.shape({
    1: PropTypes.number,
    2: PropTypes.number,
    3: PropTypes.number,
    4: PropTypes.number,
    5: PropTypes.number,
  }).isRequired,
};

export { Rating };
