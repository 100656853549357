import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './styles';
import SelectDropdown from '../../ComponentLibrary/input-elements/oc-select-dropdown';
import Text from '../../OC/oc-text';
import { SHINGLES_OPTIONS } from '../ProductFilter/ProductFilter';
import CloseIcon from '../icons/close-icon.svg';

const MODAL_NAME = 'bv-review-modal';

const toggleReviewModal = () => {
  const VIEWER_DISPLAY_VALUE = 'flex';

  const viewer = document.querySelector(`#${MODAL_NAME}`);
  const displayValue = viewer.style.display;

  if (!displayValue) {
    viewer.style.display = VIEWER_DISPLAY_VALUE;
    return true;
  }

  if (displayValue === 'none') {
    viewer.style.display = VIEWER_DISPLAY_VALUE;
    return true;
  }

  viewer.style.display = 'none';
  return false;
};

const portalElement = () => {
  const viewer = document.querySelector(`#${MODAL_NAME}`);

  if (!viewer) {
    const newElement = document.createElement('div');
    newElement.id = MODAL_NAME;
    document.body.appendChild(newElement);
    return newElement;
  }
  return viewer;
};

// eslint-disable-next-line react/prop-types
const CloseButton = ({ onClose }) => (
  <button type="button" className="oc-modal__close-button" onClick={onClose}>
    <img src={CloseIcon} alt="Close Icon" />
  </button>
);

// eslint-disable-next-line react/prop-types
const WriteReviewButton = ({ disabled = true, onClickHandler }) => (
  <button
    disabled={disabled}
    type="button"
    className="rating-button"
    onClick={onClickHandler}
  >
    Write a Review
  </button>
);

// eslint-disable-next-line react/prop-types
const ReviewModalContent = ({ formStep, children }) => {
  const className = classNames('bv_review_modal__content', {
    bv_review_modal__iframed_content: formStep === 2,
  });

  return <div className={className}>{children}</div>;
};

export const ShingleSelector = ({ productSelected, setProductSelected }) => (
  <div className="bv_review__shingle_line_selector">
    <div className="bv_review__shingle_line_selector__label">
      <Text content="Select a Shingle Line" />
    </div>
    <SelectDropdown
      value={productSelected}
      onChange={e => setProductSelected(e.target.value)}
      options={[{ value: '', text: 'Select an Item' }, ...SHINGLES_OPTIONS]}
    />
  </div>
);

ShingleSelector.propTypes = {
  productSelected: PropTypes.string.isRequired,
  setProductSelected: PropTypes.func.isRequired,
};

const ReviewModal = ({ onClose }) => {
  const [productSelected, setProductSelected] = useState('');

  const [formStep, setFormStep] = useState(1);

  const loadUnloadHandler = () => {
    document.body.style.overflow = 'hidden';
    toggleReviewModal();

    return () => {
      document.body.style.overflow = '';
      toggleReviewModal();
    };
  };

  // Uncomment for modal to lock scroll
  useEffect(loadUnloadHandler, []);

  const bazaarvoiceReviewFormURL = selectedProductName => {
    const defaultBvReviewAddress =
      'https://display.ugc.bazaarvoice.com/bvstaging/static/OwensCorning/main_site/en_US/container.htm';

    /* eslint-disable no-undef */
    const baseURL =
      String(BAZAARVOICE_UGS_REVIEW_FORM_URL).trim() === ''
        ? defaultBvReviewAddress
        : BAZAARVOICE_UGS_REVIEW_FORM_URL;
    /* eslint-enable no-undef */

    const bvaction = 'rr_submit_review';
    const bvproductId = selectedProductName;
    const bvcampaignId = 'oc.com';

    return `${baseURL}?bvaction=${bvaction}&bvproductId=${bvproductId}&bvcampaignId=${bvcampaignId}`;
  };

  return createPortal(
    <div
      css={styles}
      className="oc-modal no-target bv_review_modal"
      role="dialog"
    >
      <ReviewModalContent formStep={formStep}>
        <CloseButton onClose={onClose} />
        {formStep === 1 && (
          <div className="bv_review__select_shingle_line">
            <div>
              <ShingleSelector
                productSelected={productSelected}
                setProductSelected={setProductSelected}
              />
            </div>

            <div className="bv_review__write_review_button">
              <WriteReviewButton
                disabled={productSelected === ''}
                onClickHandler={() => setFormStep(2)}
              />
            </div>
          </div>
        )}

        {formStep === 2 && (
          <iframe
            title="BazaarVoice Review Form"
            src={bazaarvoiceReviewFormURL(productSelected)}
            className="bv_review__iframe"
          />
        )}
      </ReviewModalContent>
    </div>,
    portalElement()
  );
};

export { ReviewModal };
