// image: app/javascript/components/Builder/Library/Modules/Page/Thumbnails/ReviewContent.png
import { define, ui } from '@owenscorning/pcb.alpha';

import Renderer from '../../../../OC/PageBuilder/ReviewContent';

export default define`ReviewContent`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/ReviewContent`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  // eslint-disable-next-line react/jsx-props-no-spreading
  view: ({ value }) => <Renderer {...value} />,
  edit: {},
});
