import PropTypes from 'prop-types';
import React from 'react';

import { ReviewContent } from '../../Bazaarvoice/ReviewContent';

const initialProps = {
  ratings: 75,
  reviewTitle: 'Great product ever!',
  posterNickname: 'John Doe Junior McCormack II',
  postersZipCode: '43611',
  reviewDate: '1/23/24',
  verifiedPurchase: true,
  productName: 'Very Pink Shingle',
  productColor: 'Modern pink',
  reviewText:
    'This is the Poster’s Imagest product I have ever used. I would recommend it to anyone. It is very easy to install and looks great. I have had it for a few months now and it still looks brand new. I would definitely buy this product again.',
  posterImages: [
    'https://via.placeholder.com/100',
    'https://via.placeholder.com/200',
    'https://via.placeholder.com/100',
  ],
  bvIsRecommended: false,
  thumbsUp: 3,
  thumbsDown: 0,
};

const CMSReviewContent = () => (
  <>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <ReviewContent {...initialProps} />
  </>
);

CMSReviewContent.propTypes = {
  content: PropTypes.shape({
    copy: PropTypes.shape({}).isRequired,
  }),
};

CMSReviewContent.defaultProps = {
  content: {
    copy: {},
  },
};

export default CMSReviewContent;
