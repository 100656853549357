import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import Theme from '../../../themes/index';
import IconCheck from '../icons/icon-check';

const CheckboxLabel = styled.label`
  align-items: flex-start;
  color: ${props => (props.disabled ? '#666' : 'black')};
  display: flex;
  flex-direction: ${props => (props.labelLeft ? 'row-reverse' : 'row')};
  justify-content: ${props =>
    props.labelLeft ? 'space-between' : 'flex-start'};
  opacity: ${props => (props.disabled ? '.3' : '1')};
  position: relative;

  &:hover {
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }

  &:focus {
    outline-offset: ${props => (props.disabled ? '0' : '2px')};
    outline: ${props =>
    props.disabled ? 'none' : `${props.themeColor} solid 2px`};
  }

  &:focus:not(:focus-visible) {
    outline: none !important;
  }

  &:focus-visible {
    outline-offset: ${props => (props.disabled ? '0' : '2px')};
    outline: ${props =>
    props.disabled ? 'none' : `${props.themeColor} solid 2px`};
  }

  input[type='checkbox']:checked {
    background-color: ${props => props.themeColor};
    border-color: ${props => (props.disabled ? '#666' : props.themeColor)};
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #ffffff;
    border: 1px solid #949494;
    cursor: pointer;
    flex-shrink: 0;
    height: 16px;
    margin: 2px 8px 0 0;
    width: 16px;
  }

  input[type='checkbox'] ~ .check {
    display: none;
    height: 9px;
    left: ${props => (props.labelLeft ? 'auto' : '2px')};
    position: absolute;
    top: 6px;
    width: 12px;

    ${props => props.labelLeft === true && `right: 10px;`}
  }

  input[type='checkbox']:checked ~ .check {
    display: inline-block;
  }
`;

const Icon = styled(IconCheck)`
  left: 0;
  position: absolute;
  top: 0;
`;

const Checkbox = ({
  className,
  children,
  defaultChecked = false,
  checked,
  disabled = false,
  labelLeft = false,
  name,
  themeColor = Theme.colors.brand,
  onChange,
  value,
  ...props
}) => {
  const checkBox = useRef();
  let setChecked = () => { };

  if (checked === undefined) {
    [checked, setChecked] = useState(defaultChecked);
  }

  const updatedChecked = event => {
    setChecked(!checked);
    onChange && onChange(event);
  };

  return (
    <CheckboxLabel
      className={className}
      disabled={disabled}
      labelLeft={labelLeft}
      themeColor={themeColor}
      role="button"
      tabIndex="-1"
    >
      <input
        checked={checked}
        disabled={disabled}
        name={name}
        onChange={updatedChecked}
        readOnly={disabled ? 'readonly' : null}
        ref={checkBox}
        tabIndex={disabled ? '-1' : '0'}
        type="checkbox"
        value={value}
        {...props}
      />
      {children}
      <span
        aria-checked={checked}
        aria-disabled={disabled}
        className="check"
        role="checkbox"
      >
        <Icon fill="white" width="12px" />
      </span>
    </CheckboxLabel>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  /** This is where you put the label of the checkbox */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Set this to true, if you want the checkbox disabled */
  disabled: PropTypes.bool,
  /** Call the function you want to fire when changed */
  onChange: PropTypes.func,
  /** If you want the checkbox checked on load */
  defaultChecked: PropTypes.bool,
  /** If you want the label to be left and the check to the right */
  labelLeft: PropTypes.bool,
  /** Hex value for color theme */
  themeColor: PropTypes.string,
};
