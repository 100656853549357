import { produce } from 'immer';
import React, { useReducer, useEffect, useRef } from 'react';

import { getInsulationProducts } from './api';
import {
  studWoodOptions,
  studSpacingOptions,
  cavityInsulationTypeOptions,
  cavityTextOrProductOptions,
  exteriorTextOrThicknessOptions,
  UPDATE_ACTION,
  RESET_ACTION,
  BEGIN_PRODUCT_FETCH_ACTION,
  RETURN_PRODUCT_FETCH_ACTION,
} from './constants';
import RValueCalculatorContext from './context';
import RValueCalculatorState from './state';

function defaultState() {
  return {
    studWood: studWoodOptions[0].value,
    studSpacing: studSpacingOptions[0].value,
    cavityTextOrProduct: cavityTextOrProductOptions[0].value,
    cavityRValueText: '',
    cavityInsulationType: cavityInsulationTypeOptions[0].value,
    cavityThickness: '', // depends on data. we'll have to reconcile this in a useEffect
    exteriorTextOrThickness: exteriorTextOrThicknessOptions[0].value,
    exteriorRValueText: '',
    exteriorThickness: '', // depends on data. we'll have to reconcile this in a useEffect
  };
}

/* eslint-disable no-param-reassign */
const reducer = produce((draft, action) => {
  switch (action.type) {
    case UPDATE_ACTION:
      if (!(action.key in draft)) {
        throw new Error(`Invalid key: ${action.key}`);
      }
      if (action.value.target && 'value' in action.value.target) {
        draft[action.key] = action.value.target.value;
      } else {
        draft[action.key] = action.value;
      }
      break;
    case BEGIN_PRODUCT_FETCH_ACTION:
      draft.fetching = true;
      break;
    case RETURN_PRODUCT_FETCH_ACTION:
      draft.fetching = false;
      draft.productData = action.value;
      break;
    case RESET_ACTION:
      Object.entries(defaultState()).forEach(([key, value]) => {
        draft[key] = value;
      });
      break;
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
});
/* eslint-enable no-param-reassign */

const RValueCalculatorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState());
  useEffect(() => {
    dispatch({ type: BEGIN_PRODUCT_FETCH_ACTION });
    getInsulationProducts().then(products => {
      dispatch({
        type: RETURN_PRODUCT_FETCH_ACTION,
        value: products,
      });
    });
  }, []);
  const scrollToRef = useRef(null);
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const calculatorState = new RValueCalculatorState(
    state,
    dispatch,
    scrollToRef
  );

  return (
    <RValueCalculatorContext.Provider value={calculatorState}>
      {children}
    </RValueCalculatorContext.Provider>
  );
};

export default RValueCalculatorProvider;
