import PropTypes from 'prop-types';

import { RatingStar } from '../RatingStar';

// function takes a percent (0..100) and returns an array of 5 numbers
// that represent the fill of each star
const percentToStarFills = percentVal => {
  let percent = percentVal;
  const result = [0, 0, 0, 0, 0];

  if (percent < 0) {
    return result;
  }

  if (percent > 100) {
    return [100, 100, 100, 100, 100];
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < result.length; i++) {
    if (percent >= 20) {
      result[i] = 20 * 5;
      percent -= 20;
    } else {
      result[i] = percent * 5;
      percent = 0;
    }
  }

  return result;
};

const RatingStarLine = ({ percent = 0, outerSize = 42, innerSize = 27 }) => {
  const starFillers = percentToStarFills(percent);

  return (
    <>
      {starFillers.map((fill, index) => (
        <RatingStar
          // eslint-disable-next-line react/no-array-index-key
          key={`star-${index}`}
          fill={fill}
          outerSize={outerSize}
          innerSize={innerSize}
        />
      ))}
    </>
  );
};

RatingStarLine.propTypes = {
  percent: PropTypes.number.isRequired,
  // eslint-disable-next-line react/require-default-props
  outerSize: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  innerSize: PropTypes.number,
};

export { RatingStarLine };
