import { jsx } from '@emotion/react';
import { connectLocation } from '../location/LocationConnectors';
import styled from '@emotion/styled';

// Components
import LocationModalOpener from './oc-location-modal-opener';
import {
  FilterContent,
  FilterHeading,
  FilterWrapper,
} from './text-elements/filters';

const P = styled.p`
  margin: 0 0 0.5em;
`;

const Selectors = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

const SelectContainer = styled.div`
  color: #585858;
  display: inline-block;
  font-size: 1.125em;
  position: relative;

  &:after {
    content: '\\f107';
    display: inline;
    font-family: 'fontawesome';
    font-size: 1.125em;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Select = styled.select`
  appearance: none;
  background: #FFFFFF;
  border-radius: 0;
  border: 1px solid;
  color: #6D6D6D;
  font-size: 16px;
  line-height: 19px;
  padding: 5px 25px 5px 10px;
`;

const Zip = styled.span`
  display: inline-block;
  padding: 0 0.5em;
  position: relative;
`;

const ZipChange = styled(LocationModalOpener)`
  font-size: 0.813em;
`;

const DistanceSelector = ({
  t,
  className,
  onChange,
  options,
  defaultValue = options[0]?.value,
  zip,
  filterAttribute,
  units = 'mi',
}) => {
  const classes = ['select-container', className];

  const onSelect = e => onChange(filterAttribute, e.target.value);

  return (
    <FilterWrapper>
      <FilterHeading>
        {t('generic_locator.location_filter_heading')}
      </FilterHeading>
      <FilterContent>
        <P>{t('generic_locator.showing_location')}</P>
        <Selectors>
          <SelectContainer className={classes.join(' ').trim()}>
            <Select
              data-testid="select-radius"
              data-track-select-category="Location Radius"
              data-track-select-option={`${defaultValue} Miles`}
              data-track="select-by"
              id="select-radius"
              name="radius"
              onChange={onSelect}
              value={defaultValue}
            >
              {options?.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.displayName}
                </option>
              ))}
            </Select>
          </SelectContainer>

          <Zip>{t(`generic_locator.${units}`)} <strong>{zip}</strong></Zip>
          <ZipChange ariaLabel="change zipcode" dataTrack="change-zip-open" dataTrackModalName="Region Selector" showIcon={false} />
        </Selectors>
      </FilterContent>
    </FilterWrapper>
  )
}

DistanceSelector.filterValueType = 'singular';

export default connectLocation(DistanceSelector);
