import { define, ui } from '@owenscorning/pcb.alpha';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled'

import { Screens } from '../../../../PageBuilder/editing/Viewport';
import { BasicContent } from '../../../../OC/PageBuilder/BasicContent.jsx';
import Card from '../../../../OC/PageBuilder/Card.jsx';
import IconPimLink from '../../../../ComponentLibrary/icons/icon-pim-link';
import createSizes from "../../../../OC/PageBuilder/utilities/sizes";

const Sizes = createSizes();

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CardSet = styled.div`
  margin: 0;
  width: ${({ width }) => `${width.Mobile}px`};

  @media (min-width: ${Screens.Tablet}px) {
    width: ${({ width }) => `${width.Tablet}px`};
  }

  @media (min-width: ${Screens.Desktop}px) {
    width: ${({ width }) => `${width.Desktop}px`};
  }

  ${({cardRowCount}) => cardRowCount > 3 && css`
    .card-row {
      h3 {
        font-size: 18px!important;
        line-height: 18px!important;

        @media(min-width: 768px) {
          font-size: 20px!important;
          line-height: 20px!important;
        }
      }
    }
  `}
`;

const Renderer = ({
  settings,
  copy,
  data,
  cards: cardsProp,
  type: { source = 'custom' } = { source: 'custom' },
}) => {
  const alignment = settings?.alignment || 'Left';
  const size = settings?.containerSize?.selection || 'xl';
  const Width = Sizes[size];
  const [cards, setCards] = useState(cardsProp);

  let cardRowCount = settings?.itemsPerRow || 3;
  let desktopFits = settings?.itemsPerRow || 3;
  let tabletFits = settings?.itemsPerRowTablet || 2;
  let mobileFits = settings?.itemsPerRowMobile || 1;

  useDeepCompareEffectNoCheck(() => {
    setCards(cardsProp);
  }, [source, data, cardsProp])

  return (
    <CardContainer>
      <CardSet width={Width} size={size} cardRowCount={cardRowCount}>
        <BasicContent { ...copy } />

        <UI.Row
          center={(alignment === "Left") ? "" : true}
          fits={desktopFits}
          tablet-fits={tabletFits}
          mobile-fits={mobileFits}
          gap-3
          className="card-row"
        >
          {cards?.map((card, index) => <Card
            key={index}
            img={card?.image}
            aspectRatio={settings?.aspectRatio}
            imgAlt={card?.alt}
            prehead={card?.prehead}
            heading={card?.heading}
            posthead={card?.posthead}
            text={card?.text}
            linkText={card?.linkText}
            linkUrl={card?.url}
            url={card?.url}
            icon={card?.icon}
            transform="cards"
            ctaSwitch={card?.ctaSwitch}
            ctaProps={card?.cta}
          />)}
        </UI.Row>
      </CardSet>
    </CardContainer>
  );
};

export default define`Card Set`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/CardSet`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    copy: ui`Content/Basic`,
    type: ui`Form`.of({
      source: ui`Choices`.of({
        'custom': 'Custom',
        'data': 'Data Driven'
      })({
        label: 'Select Type',
        default: 'custom',
        disabled: true,
        mode: ui`Choices/Mode/Dropdown`,
        tip: "Field connected to PIM product.",
        tipIcon: IconPimLink
      }),
    })({
      label: 'Select Type',
    }),
    settings: ui`Form`.of({
      itemsPerRow: ui`Choices`.of(['2', '3', '4', '5', '6'])({
        label: 'Number of items per row (at desktop)',
        default: '3'
      }),
      itemsPerRowTablet: ui`Choices`.of(['2', '3', '4'])({
        label: 'Number of items per row (at tablet)',
        default: '2'
      }),
      itemsPerRowMobile: ui`Choices`.of(['1', '2'])({
        label: 'Number of items per row (at mobile)',
        default: '1'
      }),
      alignment: ui`Choices`.of(['Left', 'Center'])({
        label: 'Alignment',
        default: 'Left'
      }),
      containerSize: ui`Form`.of({
        selection: ui`Choices`.of({
          'xl': 'XL',
          'l': 'Large',
          'm': 'Medium',
        })({
          label: 'Container Size',
          default: 'xl',
          mode: ui`Choices/Mode/Dropdown`
        })
      }),
    })({
      label: 'Layout'
    }),
    cardsPlaceholder: ui`Product/PimConnected`({
      label: "Cards",
      tip: "Standards, Warranty, and Applications card data is linked to the connected PIM product and pulled from the API.",
    })
  },
});
