import { css } from '@emotion/react';

export default css`
  font-family: OCRoboto, Roboto, sans-serif;
  font-feature-settings: 'liga' off, 'clig' off;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .product-review-landing__layout {
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding: 64px 0 0 0;
  }

  .product-review-landing__filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
    }

    div:first-child {
      width: 100%;
      max-width: 364px;
    }
  }

  .product-review-landing__review-layout {
    display: flex;
    flex-direction: column;
  }

  .product-review-landing__review-layout > * {
    padding-top: 64px;
    padding-bottom: 64px;
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
  }

  .product-review-landing__review-layout > *:first-child {
    border-top: 2px solid #d1d1d1;
  }

  .product-review-landing__review-layout > *:last-child {
    border-bottom: 2px solid #d1d1d1;
  }

  .product-review-landing__disclaimer {
    font-family: OCRoboto, Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }

  .product-review-landing__black-devider {
    height: 2px;
    background-color: #000;
  }

  @media (width <= 768px) {
    .product-review-landing__layout {
      gap: 32px;
      padding: 32px 0;
    }

    .product-review-landing__review-layout > * {
      padding-top: 32px;
      padding-bottom: 32px;
      border-top: 0.5px solid #d1d1d1;
      border-bottom: 0.5px solid #d1d1d1;
    }

    .product-review-landing__review-layout > *:first-child {
      border-top: 1px solid #d1d1d1;
    }

    .product-review-landing__review-layout > *:last-child {
      border-bottom: 1px solid #d1d1d1;
    }
  }
`;
