import { define, ui, s, when } from '@owenscorning/pcb.alpha';
import { unwrapRef, wrapRef } from '../../../../../data';
import useReference from '../../../../../hooks/use_reference';
import Filter from '../../User/Filter';
import { Mobile, Tablet, Desktop } from '../../../../OC/PageBuilder/internals/Devices';
import styled from "@emotion/styled";
import { Screens } from "../../../../PageBuilder/editing/Viewport";
import migrate_content from '../../../../PageBuilder/redux/store/migrate_content';
import createSizes from "../../../../OC/PageBuilder/utilities/sizes";

const Sizes = createSizes();

const PaddingLeft = {
  [Desktop]: 85 ,
  [Tablet]: 15,
  [Mobile]: 15,
};

const ReusableWrapper = styled.div`
  display: flex;
  justify-content: ${ (props) => props.alignment === 'Left' ? 'flex-start' : 'center' };
`;

const ReusableContainer = styled.div`
  margin: 0;
  width: ${({ width }) => `${width.Mobile}px`};
  padding-left: ${({ alignment }) => `${alignment === 'Center' ? 0 : PaddingLeft.Mobile}px`};
  @media (min-width: ${Screens.Tablet}px) { width: ${({ width }) => `${width.Tablet}px`}; padding-left:  ${({ alignment }) => `${alignment === 'Center' ? 0 : PaddingLeft.Tablet}px`}; }
  @media (min-width: ${Screens.Desktop}px) { width: ${({ width }) => `${width.Desktop}px`}; padding-left:  ${({ alignment }) => `${alignment === 'Center' ? 0 : PaddingLeft.Desktop}px`}; }
  overflow-x: hidden;
`;

const SectionRenderer = ({ value, gutterlessChild }) => {
  if (!value) { return null }
  return <UI.Section
    gapless
    selectable={ false }
    details={ value.details }
    sticky={ value.sticky }
  >
    {
      (value.modules||[]).map(module => <ModuleRenderer key={ module } value={ module } gutterlessChild={ gutterlessChild } details={value.details} />)
    }
  </UI.Section>;
};

const ModuleRenderer = ({ value, gutterlessChild, details }) => {
  const definition = UI.Modules?.Page?.[value.type];
  const Component = definition?.modes?.view || (({value}) => <div></div>);
  return <UI.Module
    selectable={ false }
    styles={ definition?.styles }
    gapless={ definition?.gapless }
    gutterless={ !(details?.background === "grey") && (definition?.gutterlessForReusable || gutterlessChild) }
  >
    <Component value={ value?.data } />
  </UI.Module>
};

const Reusable = ({ reference, gutterlessChild, size = 'xl', alignment='Left' }) => {
  const { results, error, loading } = useReference(reference);

  const customAlignment = size === 'xl' ? 'Center' : alignment;
  const Width = Sizes[size];
  const editable = typeof(Board) === 'undefined' ? false : Board?.editable;

  if (loading)
    return <div>Loading...</div>;

  if (error)
    return <div>Error: {error}</div>

  if (!results) {
    if (editable) {
      return <div style={{ outline: '1px dashed red', cursor: 'pointer' }}>Please select a reusable content item</div>
    }
    return null;
  }

  const { contents, metadata } = migrate_content(results);

  // todo: keep an eye on this over page format migrations, perhaps instantiating a page renderer here would be wise
  const section = contents?.sections?.[0] || contents?.[0];
  return (
    <ReusableWrapper alignment={customAlignment}>
      <ReusableContainer width={Width} size={size} alignment={customAlignment}>
        <SectionRenderer value={ Filter(section) } gutterlessChild= { gutterlessChild } />
      </ReusableContainer>
    </ReusableWrapper>
  );
}

export default define`Reusable`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Reusable`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value: { language, reference, path, name, size, alignment } = {}, gutterlessChild = null }) => {
    const [type, value, data] = reference ? unwrapRef(reference) : ['Cms::Content', { type: 'Reusable', path, name }];
    value.language = typeof(Board) !== 'undefined' ? Board.language : language;
    return <Reusable reference={ wrapRef(type, value, data) } gutterlessChild= { gutterlessChild } size={size} alignment={alignment}/>;
  },
  gutterless: true,
  edit: ui`Form`.of({
    [s._]: ui`Heading`({ heading: 'About Reusable Content' }),
    [s._]: ui`Tip`.of('Reusable content is created to be used across multiple pages. To edit Reusable Content, please click on “Edit Master” below and you will be taken to a new page.'),
    reference: ui`Search`({
      label: 'Reusable Content',
      dataset: 'reusable',
      set: (value, path) => {
        // TODO: handle empty/null value?
        return wrapRef('Cms::Content', { type: 'Reusable', id: value.content_uuid });
      },
      get: (value) => {
        const { results, error, loading } = useReference(value);
        if (loading) {
          return <i>Loading...</i>
        } else if (error) {
          return <span>{ error }</span>
        }
        return results;
      }
    }),
    size: ui`Choices`.of({
      xl: 'XL',
      l: 'Large',
      m: 'Medium',
    })({
      label: 'Container Size',
      default: 'xl',
      mode: ui`Choices/Mode/Dropdown`
    }),
    alignment: ui`Choices`.of(['Left', 'Center'])({
      label: 'Alignment',
      default: 'Left',
      visible: when `../size`.is.equal.to('xl').then(false).otherwise(true),
    }),
  }),
});
