import PropTypes from 'prop-types';

// Styles here is a function that returns styles definition
import Styles from './styles';

const RatingStar = ({ fill = 100, outerSize = 42, innerSize = 27 }) => (
  <i css={Styles(outerSize, innerSize)}>
    <i className="star-border">
      <i className="star-body">
        <i className="star-filler" style={{ width: `${fill}%` }} />
      </i>
    </i>
  </i>
);

RatingStar.propTypes = {
  fill: PropTypes.number.isRequired,
  // eslint-disable-next-line react/require-default-props
  outerSize: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  innerSize: PropTypes.number,
};

export { RatingStar };
