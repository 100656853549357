import { ui, when, s, always, select } from '@owenscorning/pcb.alpha';
import _ from 'lodash';
import { expandRef, wrapRef } from '../../../../../data';
import useReference from '../../../../../hooks/use_reference';
import itemListResponse, { generateFilterOptions } from "./item_list_response";
import { digChoicesFromData } from '../../../Library/AttributeSet';
import MediaObject from '../../../../OC/PageBuilder/MediaObject';
import { formatDatetime } from '../../../../PageBuilder/helpers/time';
import cms_api from '../../../../../cms_api';

// helpers
import { truncateText } from '../../../../../helpers/text_truncate_helper';

const ArticleItemList = (options) => {
  /*
{
  search: {
    name: 'Blog - Roofing',
    type: 'Article::Roofing',
    preview: result => ({
      preheading: result.category,
      heading: result.proper_name
    })
  },
  list: {
    name: 'Products - Insulation (Residential)',
    attributeSet: {
      name: ...,
      path: ...
    },
    dataset: 'roofing_blog',
    filterData: (filter) => ({}),
    itemListMap: (item) => ({})
    cardSetMap: (item) => ({})
    categoryFeaturesMap: (item) => ({})
  }
}
   */
  const searchFields = ['name', 'segment', ['metadata', 'settings', 'meta', 'description'], ['metadata', 'settings', 'meta', 'keywords']];

  const blogItemListResponse = (items, showFilters, enabledFilters, allFilters, ) => {
    let results = items;
    if(items && !Array.isArray(items)) {
      results = items.results;
    }
    return itemListResponse(
      {
        items: results,
        filters: generateFilterOptions(showFilters, enabledFilters, allFilters, options.list.filterData),
        Component: (props) => {
          const content = options.list.itemListMap(props)
          const text = truncateText(content.text, 320)
          return (content ?
            <MediaObject imgSize="large" aspectRatio={{ selection: '1', custom: 1 }} {...content} text={text} /> : null)
        },
        searchFields,
        enableSearch: options.list.enableSearch,
      },
    )
  }

  const getMeta = (module) => (
    async () => {
      const query = {
        filter: {
          type: 'Cms::Content::AttributeSet',
          name: options.list.attributeSet.name,
          route: options.list.attributeSet.path?.[0] == '/' ? options.list.attributeSet.path : `/${options.list.attributeSet.path}`,
        },
        fields: {
          '*': 'contents,metadata'
        }
      }
      const data = await cms_api.get_single_for_language(query, Board.build.language)
      const hasFilters = !!data;
      const filterChoices = hasFilters ? await digChoicesFromData((options.list?.filtersBasePath?.final || 'metadata.settings.details.taxonomies'), data) : null;
      const taxonomies = hasFilters ? Object.fromEntries(Object.entries(filterChoices).map(([key, entry]) => [key, entry.label])) : {}
      const filters = hasFilters ? ui`Form`.of(
        Object.fromEntries(Object.entries(filterChoices).map(([key, { of, ...entry }]) => ([
          key,
          ui`Choices`.of(of)({ ...entry, multiple: true, includeParent: false })
        ])))
      ) : null;
      const defaultEnabledFilterKeys = Object.keys(taxonomies);
      const enabledFilters = hasFilters ? ui`ChoicesDraggable`.of(taxonomies)({
        // ** here is where we need to add the filter UI type
        label: 'Filters',
        visible: when`../showFilters`.is.equal.to(true).then(true).otherwise(false),
        default: {
          enabledFilters: defaultEnabledFilterKeys,
          orderedList: Object.keys(taxonomies).map(k => ({[k]: defaultEnabledFilterKeys.indexOf(k) >= 0}))
        },
      }) : null
      return _.merge({},
        {
          source: ui`Choices`.of({
            'all': 'All Items',
            'filtered': 'Filtered',
            'specific': 'Select Specific Items'
          })({
            label: 'Select Structure',
            default: 'all',
            mode: ui`Choices/Mode/Dropdown`
          }),
          limit: ui`Switch`({
            label: 'Limit Results?',
            default: false,
            visible: when`../source`.isnt.equal.to('specific')
          }),
          limitCount: ui`Number`({
            label: 'Limit to',
            min: 0,
            step: 1,
            visible: when`../source`.isnt.equal.to('specific').and.when`../limit`.is.equal.to(true)
          }),
          excludeEmptyPublishDates: ui`Switch`({
            label: 'Exclude Articles without a Publish Date',
            visible: when`../source`.isnt.equal.to('specific')
          }),
          nullPubDatesSortLast: ui`Switch`({
            label: 'Sort blogs without published dates last',
            visible: when`../source`.isnt.equal.to('specific').and.when`../excludeEmptyPublishDates`.isnt.equal.to(true),
          }),
          [s._]: ui`Tip`.of('By default, blogs without a published dates are shown first')({
            visible: when`../source`.isnt.equal.to('specific').and.when`../excludeEmptyPublishDates`.isnt.equal.to(true),
          }),
          attributeSet: ui`Form`.of(
            _.mapValues(options.list.attributeSet, v => always(v))
          )({
            default: options.list.attributeSet,
            visible: false
          }),
          filters: ui`List/Item`.of(filters)({
            standalone: true,
            title: 'Article Attributes',
            label: 'Filters',
            visible: when`../source`.is.equal.to('filtered')
          }),
          items: ui`List`.of({
            item: ui`Search`({
              startOpen: when`../item`.isnt.present.then(true).otherwise(false),
              label: 'Article',
              dataset: options.list.dataset,
              set: (value, path) => {
                // TODO: handle empty/null value?
                const ref = wrapRef('Cms::Content', { type: options.search.type, id: value.content_uuid })
                expandRef(ref).then(result => {
                  //Board.Change(result.metadata?.settings?.general?.proper_name, _.concat(_.initial(path), 'proper_name'));
                })
                return ref;
              },
              get: (value) => {
                const { results, error, loading } = useReference(value);
                if (loading) {
                  return <i>Loading...</i>
                } else if (error) {
                  return <span>{ error }</span>
                }
                return results;
              }
            }),
          })({
            singular: 'Article',
            title: 'proper_name',
            label: 'Articles',
            visible: when`../source`.is.equal.to('specific').then(true).otherwise(false)
          }),
        },
        module === 'ItemList' ? {
          showFilters: ui`Switch`({
            label: 'Filter Pane',
            default: hasFilters,
            disabled: !hasFilters,
          }),
          [s._]: ui`Tip`.of(`Cannot find AttributeSet <b>${[options.list.attributeSet.path, options.list.attributeSet.name].join('/')}</b> in a matching language`)({
            visible: !hasFilters,
          }),
          [s._]: ui`Tip`.of('If the item list you wish to display is to be very long, we recommend turning on the Filter Side Pane to allow users to filter down the results to their needs.')({
            visible: hasFilters,
          }),
          enabledFilters
        } : {}
      )
    }
  )

  let searchAbortController = new AbortController();

  return {
    Search: {
      name: options.search.name,
      meta: {},
      search: async ({ filter } = {}) => {
        if (!filter) {
          return null;
        }
        if (searchAbortController) searchAbortController.abort();
        searchAbortController = new AbortController();
        const language = Board.build.language;
        const query = {
          filter: {
            type: `Cms::Content::${options.search.type}`,
            language_iso_code: language,
            published: true,
            'metadata.redirect': 'null',
          },
          fields: {
            '*': 'name,metadata,language_summary,content_uuid,start_at,created_by',
          },
          q: filter,
          include: 'users',
        };
        const response = await cms_api.get_all(query, { signal: searchAbortController.signal })
        const userMap = _.keyBy(response.included, 'id');
        return response.map(r => ({
          content_uuid: r.content_uuid,
          Name: r.metadata?.settings?.details?.information?.title || r.name,
          'Last Published': <span>{ userMap[r.created_by]?.name || 'Unknown' }<br/>{ formatDatetime(r.start_at) }</span>,
        }))
      },
      preview: (result) => result && <div>{ result.metadata?.settings?.details?.information?.title || result.name }</div>
    },
    ItemList: {
      availableIn: options.list.availableIn,
      name: options.list.name,
      meta: getMeta('ItemList'),
      view: (data = null) => {
        const { data: items, meta: { parameters, filters } } = (data || { meta: {} });
        const { /*productSource, filters, items=[],*/ showFilters, enabledFilters } = parameters || {};
        let updatedFilters = filters;
        if(filters && options?.list?.filtersBasePath?.initial){ // modify filters to comply with the new structure
          // Empty the updatedFilters object for the updated keys
          updatedFilters = {};

          Object.keys(filters).forEach(key => {
            let newKey = key;
            // Check if the key starts with the initial basePath
            if (key.startsWith(options.list.filtersBasePath.initial)) {
              // Replace the initial basePath with the final basePath
              newKey = key.replace(options.list.filtersBasePath.initial, options.list.filtersBasePath.final);
            }
            // Update the key in the updated filter
            updatedFilters[newKey] = filters[key];
          });

        }
        return blogItemListResponse(items, showFilters, enabledFilters, updatedFilters || {})
      }

    },
    CardSet: {
      availableIn: options.list.availableIn,
      name: options.list.name,
      meta: getMeta('CardSet'),
      view: (data = null) => {
        const { data: items, meta: { parameters, filters } } = (data || { meta: {} });
        return (items || []).map(options.list.cardSetMap || (i => i))
      }
    },
    CategoryFeatures: {
      availableIn: options.list.availableIn,
      name: options.list.name,
      meta: getMeta('CategoryFeatures'),
      view: (data = null) => {
        const { data: items, meta: { parameters, filters } } = (data || { meta: {} });
        return (items || []).map(options.list.categoryFeaturesMap || (i => i))
      }
    }
  }
}

export default ArticleItemList;
