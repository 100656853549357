import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles';
import Text from '../../OC/oc-text';

const OPTIONS = [
  { value: '5', text: '5 stars' },
  { value: '4', text: '4 stars' },
  { value: '3', text: '3 stars' },
  { value: '2', text: '2 stars' },
  { value: '1', text: '1 star' },
];

export const RatingFilter = ({ ratingsSelected = [], setRatingsSelected }) => (
  <div css={styles}>
    <p className="rating-filter__label">
      <Text content="Filter reviews by star rating" />
    </p>
    <ul className="rating-filter__list">
      {OPTIONS.map(option => {
        const isSelected = ratingsSelected.includes(option.value);
        return (
          <li
            key={option.value}
            className={classNames('rating-filter__list-item', {
              'rating-filter__list-item--selected': isSelected,
            })}
          >
            <input
              type="checkbox"
              className="rating-filter__list-item-checkbox"
              id={option.value}
              value={option.value}
              checked={isSelected}
              onChange={() => setRatingsSelected(option.value)}
            />
            <label
              htmlFor={option.value}
              className="rating-filter__list-item-label"
            >
              <span>{option.text}</span>
            </label>
          </li>
        );
      })}
    </ul>
  </div>
);

RatingFilter.propTypes = {
  ratingsSelected: PropTypes.arrayOf(PropTypes.string).isRequired,
  setRatingsSelected: PropTypes.func.isRequired,
};
