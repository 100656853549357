export function addDtmEvent(name, info, contractor) {
  window.adobeDataLayer = window.adobeDataLayer || [];

  if (contractor) {
    info["contractor"] = {
      "contractorID": contractor.id,
      "contractorName": contractor.store_name,
      "contractorType": contractor.loyalty_program_code,
      "reviewStars": contractor.gq_review_stars,
      "reviewCount": contractor.gq_review_count
    }
  }
  adobeDataLayer.push({
    "event": name,
    "eventInfo": info
  })
};
