import styled from '@emotion/styled';

const LIGHT_GRAY_BG = '#e6e6e6';
const TABLE_ROW_DARK = 'rgb(248, 248, 248)';
const TABLE_DIVIDER_COLOR = 'rgb(188, 188, 188)';
const TABLET_BREAKPOINT = '768px';

export const ModuleContainer = styled.div`
  margin-top: 32px;

  @media (min-width: ${TABLET_BREAKPOINT}) {
    display: flex;
    flex-direction: row;
    gap: 29px;
  }
  > div {
    height: fit-content;
  }
  hr.divider {
    border: 1px solid lightgray;
    margin: 16px 0;
  }
  hr.underline {
    border: 1px solid black;
    width: 100%;
    margin: -8px 0 16px;
  }

  h3 {
    font-size: 24px;
    font-family: 'OCRoboto', sans-serif;
    line-height: 1.2;
    text-transform: none;
    font-weight: 700;
  }
`;

export const InputsContainer = styled.div`
  background: ${LIGHT_GRAY_BG};
  padding: 14px;
  flex-shrink: 0;

  width: 100%;
  @media (min-width: ${TABLET_BREAKPOINT}) {
    width: 360px;
  }

  .rinput {
    position: relative;

    &::after {
      content: 'R-';
      position: absolute;
      left: 12px;
      bottom: 16px;
    }

    input[type='number'] {
      padding-left: 30px;
    }
  }

  .radioGroup,
  .rinput,
  > div {
    margin: 8px 0 16px;
  }
  .radioGroup label {
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    margin: 10px 0;
  }

  button {
    margin: 16px 12px 0 0;
  }
`;

export const ResultsContainer = styled.div`
  width: -webkit-fill-available;

  padding-top: 10px;

  .pink {
    color: #d40f7d;
  }

  .assumptions {
    p,
    li {
      margin-bottom: 4px;
    }
    ul {
      margin-top: 4px;
    }
  }

  table {
    margin-top: 20px;
    width: 100%;
    border-spacing: 0px;
    tr:nth-child(odd) {
      background: ${TABLE_ROW_DARK};
    }
    td {
      height: 54px;
      width: 50%;
      padding: 10px 15px;
      border-bottom: 1px solid ${TABLE_DIVIDER_COLOR};
      border-right: 1px solid ${TABLE_DIVIDER_COLOR};
      position: relative;
      text-align: left;
    }
    td:last-child {
      border-right: 0;
      text-align: center;
    }

    margin-bottom: 16px;
  }
`;
