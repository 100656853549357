import { useEffect } from 'react';

// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values#Whitespace_keys
const ENTER_KEY = 'Enter';
const SPACEBAR_KEY = ' ';
const SPACEBAR_KEY_OLD = 'Spacebar';

const TRIGGER_KEYS = [ENTER_KEY, SPACEBAR_KEY, SPACEBAR_KEY_OLD];

function useAccessibleInteractivity(reactRef, callback) {
  useEffect(() => {
    reactRef.current.tabIndex = 0;

    reactRef.current.onclick = event => {
      callback(event);

      // Remove outline shown when object is focused
      event.currentTarget.blur();
    };

    reactRef.current.onkeydown = event => {
      const pressedTriggerKey = TRIGGER_KEYS.find(key => key === event.key);
      if (!pressedTriggerKey) {
        return;
      }

      callback(event);

      if (
        pressedTriggerKey === SPACEBAR_KEY ||
        pressedTriggerKey === SPACEBAR_KEY_OLD
      ) {
        // Stop browser from scrolling down
        event.preventDefault();
      }
    };
  }, [reactRef, callback]);
}

export default useAccessibleInteractivity;
