/* eslint-disable react/prop-types */
import React from 'react';

import Inputs from './Inputs';
import Results from './Results';
import Provider from './provider';
import { ModuleContainer } from './styled_components';
import BasicContent from '../BasicContent';

const RValueCalculator = props => {
  const {
    value: {
      content: { heading },
    },
  } = props;
  return (
    <Provider>
      <BasicContent content={heading} />
      <ModuleContainer>
        <Inputs />
        <Results />
      </ModuleContainer>
    </Provider>
  );
};

export default RValueCalculator;
