import { css } from '@emotion/react';

export default css`
  display: flex;
  justify-content: center;

  color: #333;
  font-family: OCRoboto, Roboto, sans-serif;
  font-feature-settings: 'liga' off, 'clig' off;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .contact-us__layout {
    max-width: 570px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .contact-us__title {
    color: #000;
    font-family: 'OCOswald', Oswald, 'OCRoboto', Roboto, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    text-transform: uppercase;
  }

  .contact-us__content {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .contact-us__phone-number {
    color: #d40f7d;
    font-family: OCRoboto, Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
  }
`;
