import { define, ui, select, s, when } from '@owenscorning/pcb.alpha';
import _ from 'lodash';
import Renderer from './../../../../OC/PageBuilder/Form.jsx';


const getFormData = _.memoize(() => {
  if (typeof(window) !== 'undefined' && typeof(window.PB_FORM_DATA) !== 'undefined' && window.PB_FORM_DATA.data) {
    return window.PB_FORM_DATA.data;
  }
  return [];
});

const getForms = _.memoize(() => {
  return _.orderBy(getFormData().map(formData => (
    {
      label: formData.name,
      value: formData.uid
    }
  )), [form => form.label.toLowerCase()]);
});

const getFormUrlLookup = _.memoize(() => {
  return Object.fromEntries(getFormData().map(entry => [entry.uid, entry.url]));
});

export default define`Form`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Form`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value, path }) => <Renderer { ...value } path={path} />,
  edit: {
    containerSettings: ui`Form`.of({
      size: ui`Choices`.of({
        'xl': 'XL',
        'l': 'Large',
        'm': 'Medium',
      })({
        label: 'Size',
        default: 'xl',
        mode: ui`Choices/Mode/Dropdown`
      }),
      alignment: ui`Choices`.of(['Left', 'Center'])({
        label: 'Alignment',
        default: 'Left'
      }),
    })({
      label: "Container"
    }),
    form_uid: ui`Choices`.of(getForms())({ label: 'Form' }),
    [s._]: ui`Tip`.of('These are existing forms and cannot be customized via Page Builder.  If you are not familiar with what these are based on the titles in the dropdown, please consult a member of the digital team for help.'),
    form_url: ui`Hidden`({ value: select`../form_uid`.from(getFormUrlLookup()) }),
    success_content: ui`Form`.of({
      type: ui`Choices`.of({
        form: 'from Form definition',
        url: 'URL'
      })({
        label: 'Type',
        default: 'form'
      }),
      url: ui`Url`({
        label: 'URL',
        visible: when`../type`.is.equal.to('url')
      })
    })({
      label: 'Success Content',
      visible: when`../form_uid`.is.present().and.when`../form_url`.isnt.present()
    })
  },
});
