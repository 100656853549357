import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { helmetJsonLdProp } from 'react-schemaorg';

import { reviewContentItemMapper } from '../BazzarvoiceReviews/utils';

const SchemaMarkup = ({ reviewContentItems }) => {
  const reviewsSchema = reviewContentItems.map(reviewContentItem => {
    const item = reviewContentItemMapper(reviewContentItem);
    return {
      '@context': 'http://schema.org',
      '@type': 'Review',
      author: {
        '@type': 'Person',
        name: item.posterNickname,
      },
      itemReviewed: {
        '@type': 'Product',
        name: item.productName,
      },
      reviewRating: {
        '@type': 'Rating',
        ratingValue: item.ratings,
        bestRating: 5,
        worstRating: 1,
      },
    };
  });

  return <Helmet script={[helmetJsonLdProp(reviewsSchema)]} />;
};

SchemaMarkup.propTypes = {
  reviewContentItems: PropTypes.arrayOf(
    PropTypes.shape({
      posterNickname: PropTypes.string,
      productName: PropTypes.string,
      ratings: PropTypes.number,
    })
  ).isRequired,
};

export { SchemaMarkup };
