import { useState, useRef } from 'react';
import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { H3 } from '../ComponentLibrary/text-elements/oc-h.jsx';
import Image from './oc-image';
import { useAccessibleInteractivity } from "../../hooks";
import useDeviceBreakpoint from '../../hooks/use_device_breakpoint.js';

const GroupInfoContainer = styled.div`
  border-bottom: 1px solid black;
  background: #e6e6e6;
  padding: 0 8px;
  z-index: 2;
  position: ${ props => props.sticky ? 'sticky' : 'relative' };
  top: 0;

  @media(min-width: 1200px) {
    padding: 0 16px;
  }
`;

const Label = styled.div`
  min-height: 70px;
  font-weight: 700;
  display: flex;
  align-items: center;

  @media(min-width:  1200px) {
    min-height: 90px;
  }

  &:hover {
    cursor: ${props => props.collapsible ? 'pointer' : 'default'};
  }

  &:focus {
    outline-offset: 0;
    outline: ${props => props.collapsible ? '#d40f7d solid 2px' : '0'};
  }

  &:focus:not(:focus-visible) {
    outline: none!important;
  }

  &:focus-visible {
    outline-offset: 0;
    outline: ${props => props.collapsible ? '#d40f7d solid 2px' : '0'};
  }

  .info-badge {
    width: auto;
    max-width: 50%;

    @media(min-width:  768px) {
      display: inline-block;
      max-height: 40px;
      margin-right: 8px;
    }

    @media(min-width:  1200px) {
      max-height: 60px;
      margin-right: 16px;
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .content-group-title {
    text-transform: none;
    margin: 0;
  }
`;

const Chevron = styled.span`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  padding-right: 24px;
  align-self: center;

  &:after {
    font-family: "icomoon";
    content: "\\e608";
    position: absolute;
    transform: ${props => props.collapsed ? 'rotate(90deg)' : 'rotate(-90deg)' };
    font-size: 18px;
    right: 2px;
    color: black;
  }
`;

const Content = styled.div`
  display: ${props => props.collapsed ? 'none' : 'block'};
  padding: 0 0 24px;

  p {
    margin-top: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Icon = ({ src, alt }) => {
  const device = useDeviceBreakpoint();

  const height = device === 'desktop' ? 60 : 40;
  return (
    <Image
      className="info-badge"
      src={src}
      alt={alt}
      transform={[{ height }]}
    />
  );
};

const GroupInfoDropdown = ({ alt, badge, children, collapsed=true, collapsible=true, ctaLabel, dataLabel, label, sticky=true }) => {
  const contentDropdown = useRef();
  const [expanded, setExpanded] = useState(collapsed == true ? false : true);

  const toggleDropdown = () => {
    if (collapsible) {
      setExpanded(!expanded);
    }
  }

  useAccessibleInteractivity(contentDropdown, toggleDropdown);

  return (
    <GroupInfoContainer className="group-info-container" sticky={ sticky }>
      <Label
        aria-expanded={expanded ? 'true' : 'false'}
        collapsible={ collapsible }
        ref={ contentDropdown }
        role="button"
        tabIndex={ collapsible ? '0' : '-1' }
        data-track-accordion={dataLabel || label}
        data-track={expanded ? 'conceal' : 'reveal'}
      >
        { badge &&  <Icon src={badge} alt={alt} />}

        <TitleContainer>
          <H3 className="content-group-title" font="body">{label}</H3>

          { collapsible &&
            <Chevron collapsed={expanded === false ? collapsed = true : null}>
              {ctaLabel && ctaLabel}
            </Chevron>
          }
        </TitleContainer>
      </Label>

      <Content collapsed={expanded === false ? collapsed = true : null}>
        {children}
      </Content>
    </GroupInfoContainer>
  )
}

export default GroupInfoDropdown;

