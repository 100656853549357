import PropTypes from 'prop-types';
import { useRef } from 'react';

import styles from './styles';
import usePhotoViewerCarousel from './usePhotoViewerCarousel';
import IconChevron from '../../ComponentLibrary/icons/icon-chevron';

// TODO: Fix the import of the Image component
//
// This component has some problems with the import in Storybook
// For now it is not critical to use it. Let's comment it out and fix it later
//
// import Image from '../../OC/oc-image';

export const PhotoViewerCarousel = ({ photos, initialPhotoSelected = 0 }) => {
  const carouselElement = useRef();
  const {
    currentPhotoIndex,
    scrollToNextPhoto,
    scrollToPrevPhoto,
    calculateCurrentPhotoIndex,
  } = usePhotoViewerCarousel({
    carouselElement,
    initialPhotoSelected,
  });

  return (
    <div css={styles}>
      {photos.length > 1 && (
        <>
          {currentPhotoIndex > 0 && (
            <button
              type="button"
              className="photo-viewer-modal__photo-carousel-button photo-viewer-modal__prev"
              onClick={scrollToPrevPhoto}
            >
              <IconChevron fill="#585858" height="24px" width="24px" />
            </button>
          )}
          {currentPhotoIndex < photos.length - 1 && (
            <button
              type="button"
              className="photo-viewer-modal__photo-carousel-button photo-viewer-modal__next"
              onClick={scrollToNextPhoto}
            >
              <IconChevron fill="#585858" height="24px" width="24px" />
            </button>
          )}
        </>
      )}
      <ul
        ref={carouselElement}
        className="photo-viewer-modal__photo-carousel-list"
        onScroll={calculateCurrentPhotoIndex}
      >
        {photos.map(photo => (
          <li
            key={photo.id}
            className="photo-viewer-modal__photo-carousel-item"
          >
            <img
              src={photo.large}
              alt={photo.caption || "Poster's photography"}
              className="photo-viewer-modal__photo"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

PhotoViewerCarousel.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/require-default-props
  initialPhotoSelected: PropTypes.number,
};
