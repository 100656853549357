import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import Styles from './styles';

const RatingLine = ({ value = 0, total = 0 }) => (
  <div className="rating-line">
    <div
      className="rating-line_filler"
      style={{ width: `${(parseInt(value, 10) / parseInt(total, 10)) * 100}%` }}
      key={value}
    />
  </div>
);

RatingLine.propTypes = {
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

const sortedKeys = starRating => Object.keys(starRating).sort((a, b) => b - a);
const starText = key => `${key} ${pluralize('Star', parseInt(key, 10))}`;

const RatingTable = ({
  totalReviews = 0,
  starRating = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  },
}) => (
  <div css={Styles}>
    <div className="rating-grid">
      {sortedKeys(starRating).map(key => (
        <div key={key} className="rating-grid_row">
          <div className="rating-grid_cell">{starText(key)}</div>
          <div className="rating-grid_cell">
            <RatingLine value={starRating[key]} total={totalReviews} />
          </div>
          <div className="rating-grid_cell">{starRating[key]}</div>
        </div>
      ))}
    </div>
  </div>
);

RatingTable.propTypes = {
  totalReviews: PropTypes.number.isRequired,
  starRating: PropTypes.shape({
    1: PropTypes.number.isRequired,
    2: PropTypes.number.isRequired,
    3: PropTypes.number.isRequired,
    4: PropTypes.number.isRequired,
    5: PropTypes.number.isRequired,
  }).isRequired,
};

export { RatingTable };
