import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import styles from './styles';
import Text from '../../OC/oc-text';
import { PhotoViewerCarousel } from '../PhotoViewerCarousel';
import { RatingStarLine } from '../RatingStarLine';
import CloseIcon from '../icons/close-icon.svg';

const togglePhotoViewerModal = () => {
  const VIEWER_DISPLAY_VALUE = 'flex';

  const viewer = document.querySelector('#photo-viewer-modal');
  const displayValue = viewer.style.display;

  if (!displayValue) {
    viewer.style.display = VIEWER_DISPLAY_VALUE;
    return true;
  }

  if (displayValue === 'none') {
    viewer.style.display = VIEWER_DISPLAY_VALUE;
    return true;
  }

  viewer.style.display = 'none';
  return false;
};

const portalElement = () => {
  const element = document.querySelector('#photo-viewer-modal');
  if (!element) {
    const newElement = document.createElement('div');
    newElement.id = 'photo-viewer-modal';
    document.body.appendChild(newElement);
    return newElement;
  }
  return element;
};

export const PhotoViewerModal = ({
  onClose,
  ratings,
  posterNickname,
  postersZipCode,
  productName,
  productColor,
  reviewTitle,
  reviewText,
  reviewDate,
  posterImages,
  initialPhotoSelected = 0,
}) => {
  const loadUnloadHandler = () => {
    document.body.style.overflow = 'hidden';
    togglePhotoViewerModal();

    return () => {
      document.body.style.overflow = '';
      togglePhotoViewerModal();
    };
  };

  // Uncomment for modal to lock scroll
  useEffect(loadUnloadHandler, []);

  return createPortal(
    <div
      css={styles}
      className="oc-modal no-target photo-viewer-modal"
      role="dialog"
    >
      <section className="photo-viewer-modal__body">
        <button
          type="button"
          className="photo-viewer-modal__close-button"
          onClick={onClose}
        >
          <img src={CloseIcon} alt="Close Icon" />
        </button>
        <PhotoViewerCarousel
          photos={posterImages}
          initialPhotoSelected={initialPhotoSelected}
        />
        <div className="photo-viewer-modal__content">
          <span>
            <RatingStarLine
              percent={(ratings / 5) * 100}
              outerSize={20}
              innerSize={10}
            />
          </span>
          <h3 className="photo-viewer-modal__title">{reviewTitle}</h3>
          <p className="photo-viewer-modal__text">
            <b>Product:</b> <Text content={productName} />
            {productColor && (
              <>
                <span className="photo-viewer-modal__attribute-delimiter">
                  |
                </span>
                <b>Color:</b> <Text content={productColor} />
              </>
            )}
          </p>
          {postersZipCode && (
            <p className="photo-viewer-modal__text">
              <b>Location:</b> <Text content={postersZipCode} />
            </p>
          )}
          <p className="photo-viewer-modal__text">
            <b>
              <Text content={posterNickname} />
            </b>
          </p>
          <p className="photo-viewer-modal__text photo-viewer-modal__text--small">
            <Text content={reviewDate} />
          </p>
          <p className="photo-viewer-modal__text photo-viewer-modal__text--small">
            <Text content={reviewText} />
          </p>
        </div>
      </section>
    </div>,
    portalElement()
  );
};

PhotoViewerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  ratings: PropTypes.number.isRequired,
  posterNickname: PropTypes.string.isRequired,
  postersZipCode: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  productColor: PropTypes.string.isRequired,
  reviewDate: PropTypes.string.isRequired,
  reviewTitle: PropTypes.string.isRequired,
  reviewText: PropTypes.string.isRequired,
  posterImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialPhotoSelected: PropTypes.number,
};

export default PhotoViewerModal;
