import { define, ui, s } from '@owenscorning/pcb.alpha';
import styled from '@emotion/styled';

import { BasicContent } from './../../../../OC/PageBuilder/BasicContent.jsx';
import { Mobile} from '../../../../OC/PageBuilder/internals/Devices';
import { Screens } from '../../../../PageBuilder/editing/Viewport';
import createSizes from "../../../../OC/PageBuilder/utilities/sizes";


const Sizes = createSizes({ m: { [Mobile]: 345 } });

const TableWrapper = styled.div`
  display: flex;
  justify-content: ${ (props) => props.alignment === 'Left' ? 'flex-start' : 'center' };
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
  width: ${({ width }) => `${ width.Mobile }px`};
  @media (min-width: ${ Screens.Tablet }px) { width: ${({ width }) => `${ width.Tablet}px` }; }
  @media (min-width: ${ Screens.Desktop }px) { width: ${({ width }) => `${ width.Desktop}px` }; }
`;

const Modal = {
  title: 'Edit Table',
  body: ui`Table`,
  buttons: { Submit: (data) => {
    Board.modal.reference(data);
    Board.modal.close();
  } }
};

const Renderer = ({ copy, containerSize, alignment, table, after }) => {
  const size = containerSize?.selection || 'xl';
  const Width = Sizes[size];

  return (
    <TableWrapper alignment={ alignment }>
      <TableContainer width={ Width } size={ size }>
        <BasicContent { ...copy } />
        <UI.Table value={ table } />
        <UI.Text.Rich content={ after } />
      </TableContainer>
    </TableWrapper>
  );
};

export default define`Table`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Table`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    copy: ui`Content/Basic`,
    containerSize: ui`Form`.of({
      selection: ui`Choices`.of({
        'xl': 'XL',
        'l': 'Large',
        'm': 'Medium',
      })({
        label: 'Container Size',
        default: 'xl',
        mode: ui`Choices/Mode/Dropdown`
      })
    }),
    alignment: ui`Choices`.of(['Left', 'Center'])({
      label: 'Alignment',
      default: 'Left'
    }),
    table: [
      ({ value, onChange }) => <UI.CTA
        text="Edit Table"
        onClick={ () => Board.modal.open(Modal, (data) => onChange(data), value) }
      />,
      {
        label: 'Table'
      }
    ],
    after: ui`Text/Rich`({
      sublabel: 'Text After Table'
    })
  },
});
