import { css } from '@emotion/react';

export default css`
  position: relative;
  height: 100%;
  max-width: 100%;

  @media (min-height: 663px) {
    height: 663px;
    max-height: 663px;
  }

  .photo-viewer-modal__photo-carousel-list {
    height: 100%;
    display: flex;
    gap: 2px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    background-color: #d9d9d9;
    margin: 0;
    padding: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .photo-viewer-modal__photo-carousel-item {
    flex: 0 0 100%;
    scroll-snap-align: start;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .photo-viewer-modal__photo {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;

    @media (min-width: 884px) {
      max-width: 884px;
    }
  }

  .photo-viewer-modal__photo-carousel-button {
    position: absolute;
    background: #ffffff;
    opacity: 0.5;
    padding: 0;
    border: none;
    width: 44px;
    height: 48px;
    top: calc(50% - 24px);
    cursor: pointer;
    z-index: 1;
  }

  .photo-viewer-modal__prev {
    svg {
      transform: rotate(90deg);
    }
  }

  .photo-viewer-modal__next {
    right: 0;

    svg {
      transform: rotate(-90deg);
    }
  }
`;
