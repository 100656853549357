import { define, ui, when, s } from '@owenscorning/pcb.alpha';

import Iframe from '../../../../ComponentLibrary/oc-iframe';
import { BasicContent } from '../../../../OC/PageBuilder/BasicContent.jsx';
import createSizes from "../../../../OC/PageBuilder/utilities/sizes";
import styled from "@emotion/styled";
import { Screens } from "../../../../PageBuilder/editing/Viewport";

const Sizes = createSizes();

const IframeWrapper = styled.div`
display: flex;
justify-content: ${ (props) => props.alignment === 'Left' ? 'flex-start' : 'center' };
`;

const IframeContainer = styled.div`
  margin: 0;
  width: ${({ width }) => `${ width.Mobile}px` };
  @media (min-width: ${ Screens.Tablet }px) { width: ${ ({ width }) => `${ width.Tablet }px`}; }
  @media (min-width: ${ Screens.Desktop }px) { width: ${ ({ width }) => `${ width.Desktop }px`}; }
`;

const Renderer = ({ copy, pym, iframeId, iframeSrc, iframeSettings, containerSettings }) => {
  const { size, alignment } = containerSettings;
  const Width = Sizes[(size || 'xl')];
  return (
    <IframeWrapper alignment={alignment}>
      <IframeContainer size={size} width={Width}>
        <BasicContent { ...copy } />
        <Iframe
          pymjs={ pym?.pymjs }
          src={ iframeSrc?.src }
          iframeId={ iframeId?.id }
          scrolling={ iframeSettings?.scrolling}
          desktopHeight={ iframeSettings?.desktopHeight}
          tabletHeight={ iframeSettings?.tabletHeight}
          mobileHeight={ iframeSettings?.mobileHeight}
        />
      </IframeContainer>
    </IframeWrapper>
  )
};

export default define`Iframe`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Iframe`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {
    copy: ui`Content/Basic`,
    containerSettings: ui`Form`.of({
      size: ui`Choices`.of({
        'xl': 'XL',
        'l': 'Large',
        'm': 'Medium',
      })({
        label: 'Size',
        default: 'xl',
        mode: ui`Choices/Mode/Dropdown`
      }),
      alignment: ui`Choices`.of(['Left', 'Center'])({
        label: 'Alignment',
        default: 'Left'
      }),
    })({
      label: "Container"
    }),
    pym: ui`Form`.of({
      pymjs: ui`Switch`({
        label: 'Use pym.js for height',
        default: false
      }),
      [s._]: ui`Tip`.of('To use pym.js, you must be able to add a script to the page you are iframing.'),
    }),
    iframeId: ui`Form`.of({
      id: ui`Text`({label: 'Iframe Id'})
    })({
      visible: when`../pym/pymjs`.is.equal.to(true)
    }),
    iframeSrc: ui`Form`.of({
      src: ui`Text`({label: 'source of iframe'})
    }),
    iframeSettings: ui`Form`.of({
      scrolling: ui`Choices`.of({
        'auto': 'Auto',
        'no': 'No Scrolling'
      })({
        label: 'Scrolling',
        default: 'auto'
      }),
      desktopHeight: ui`Text`({label: 'Desktop Height'}),
      tabletHeight: ui`Text`({label: 'Tablet Height'}),
      mobileHeight: ui`Text`({label: 'Mobile Height'}),
      [s._]: ui`Tip`.of('You can use px, %, em, or rem. Example: 300px')
    })
    ({
      label: 'Iframe Settings',
      visible: when`../pym/pymjs`.is.equal.to(false)
    })
  },
});
