import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Provider } from 'react-redux';

import { connectLocation } from './LocationConnectors';
import { makeCountryOption, CountryPicker } from './Popup/CountryPicker';
import { localeSort } from './locales';
import Theme from '../../themes/index';
import Button from '../ComponentLibrary/oc-button';
import Icon from '../OC/oc-icon';
import { store } from '../Store';

const BannerContainer = styled.div`
  background: ${'#FFF1E5'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  .error {
    color: #d65900;
    font-weight: 700;
    margin-right: 16px;
    padding: 0;
  }

  span {
    margin-right: 16px;
  }

  a {
    margin-right: 16px;
  }

  .banner-error {
    margin-left: 16px;
  }

  .country-picker-container {
    width: 250px;
    margin-right: 16px;
    margin-bottom: -18px;
  }
`;

const Close = styled.span`
  font-size: 22px;
  &:hover {
    color: ${Theme.colors.brand};
    cursor: pointer;
  }
`;

const CrossingAlertBanner = data => {
  const { availableLocales, locale, userLocale, changeLocation, t } = data;

  const localeCode = locale.code;
  const userLocaleCode = userLocale.code;
  const [languageCode, countryCode] = localeCode.split('-');
  const [_userLanguageCode, userCountryCode] = userLocaleCode.split('-');

  const availableCountries = availableLocales.map(l => l.code.split('-')[1]);

  const [selectedCountry, setSelectedCountry] = useState(
    availableCountries.includes(userCountryCode) ? userCountryCode : undefined
  );

  // Initialize state with value from sessionStorage or default to empty string
  const [hasUserClosed, setUserClosed] = useState(
    sessionStorage.getItem('hasUserClosedCrossingLocaleBanner') ?? false
  );

  const [isVisible, setIsVisible] = useState(
    selectedCountry !== countryCode && !hasUserClosed
  );

  const handleClose = () => {
    setUserClosed(true);
    sessionStorage.setItem('hasUserClosedCrossingLocaleBanner', 'true');
    setIsVisible(false);
  };

  const handleContinue = () => {
    const nextLocale =
      availableLocales.find(l => l.code.split('-')[1] === selectedCountry) ||
      availableLocales[0];

    // changeLocation doesn't trigger a full locale change
    // if the current page locale is the same as the desired locale,
    // so we fall back to just closing the banner for the session.
    if (nextLocale.code === locale.code) {
      handleClose();
      return;
    }

    changeLocation({ locale: nextLocale });
  };

  const countryOptions = {};
  availableLocales.sort(localeSort).forEach(l => {
    const [lCode, cCode] = l.code.split('-');
    if (!countryOptions[cCode] || lCode === languageCode) {
      countryOptions[cCode] = makeCountryOption(l, true);
    }
  });

  return (
    isVisible && (
      <BannerContainer>
        <Icon type="exclamation-triangle" className="error banner-error" />
        <span>{t(`crossing_locale.for_local_information`)}</span>
        <div className="country-picker-container">
          <CountryPicker
            options={Object.values(countryOptions)}
            value={countryOptions[selectedCountry]}
            setCountry={setSelectedCountry}
            data-track="change-zip"
            compact
          />
        </div>

        <Button color="white" onClick={handleContinue} data-track="change-zip">
          {t(`crossing_locale.continue`)}
        </Button>
        <Close onClick={handleClose}>&times;</Close>
      </BannerContainer>
    )
  );
};

const ConnectedCrossingAlertBanner = connectLocation(
  ({ availableLocales, locale, userLocale, changeLocation, t }) => (
    <CrossingAlertBanner
      availableLocales={availableLocales}
      locale={locale}
      userLocale={userLocale}
      changeLocation={changeLocation}
      t={t}
    />
  )
);

const ProvidedCrossingAlertBanner = () => (
  <Provider store={store}>
    <ConnectedCrossingAlertBanner />
  </Provider>
);

export default ProvidedCrossingAlertBanner;
export const TestCrossingAlertBanner = CrossingAlertBanner;
