import { Desktop, Mobile, Tablet } from "../internals/Devices";

const defaultSizes = {
  xl: { [Mobile]: 345, [Tablet]: 738, [Desktop]: 1170 },
  l: { [Mobile]: 345, [Tablet]: 738, [Desktop]: 970 },
  m: { [Mobile]: 225, [Tablet]: 482, [Desktop]: 770 },
};

/**
 * Creates sizes with customizable overrides and additional keys.
 * @param {Object} overrides - Custom size values to override defaults.
 * @param {Object} additionalSizes - Additional size definitions beyond defaults.
 * @returns {Object} - Merged size object with defaults, overrides, and additional sizes.
 */
const createSizes = (overrides = {}, additionalSizes = {}) => {
  return {
    xl: { ...defaultSizes.xl, ...overrides.xl },
    l: { ...defaultSizes.l, ...overrides.l },
    m: { ...defaultSizes.m, ...overrides.m },
    ...additionalSizes, // Additional keys beyond defaults
  };
};


export default createSizes;
