import { css } from '@emotion/react';

export default css`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  font-family: OCRoboto, Roboto, sans-serif;
  font-feature-settings: 'liga' off, 'clig' off;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .review-content__layout {
    display: flex;
    flex-direction: row;
    gap: 64px;
  }

  .review-content__sidebar {
    width: 130px;
    padding-top: 50px;
    display: flex;
    gap: 8px;
    flex-direction: column;
  }

  .review-content__sidebar-nickname {
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .review-content__sidebar-date-verified {
    display: contents;
  }

  .review-content__sidebar-date {
    font-weight: 700;
  }

  .review-content__sidebar-verified {
    display: flex;
    gap: 5px;
    align-items: center;
    white-space: nowrap;
  }

  .review-content__main {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .review-content__main-rating {
    display: flex;
  }

  .review-content__main-product-name {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
  }

  .review-content__main-product-attributes {
    font-size: 16px;
    display: flex;
    gap: 8px;
  }

  .review-content__main-zip-code {
    font-size: 16px;
  }

  .review-content__main-review-text {
  }

  .review-content__main-photos {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .review-content__main-photos-button {
    background: none;
    border: none;
    padding: 0;
  }

  .review-content__main-photos-image {
    cursor: pointer;
  }

  .review-content__main-photos-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    background-color: #f5f5f5;
  }

  .review-content__main-recommend-checkbox {
    display: flex;
    gap: 5px;
  }

  .review-content__main-helpful-vote {
    display: flex;
    gap: 8px;
  }

  @media (width <= 768px) {
    .review-content__layout {
      flex-direction: column;
      gap: 12px;
    }

    .review-content__sidebar {
      display: contents;
      width: auto;
    }

    .review-content__main {
      max-width: none;
      display: contents;
    }

    .review-content__main-zip-code {
      display: none;
    }

    .review-content__sidebar-date {
      order: 1;
      color: #585858;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }

    .review-content__main-rating {
      order: 1;
    }

    .review-content__main-product-name {
      order: 2;
      font-size: 22px;
      line-height: 22px;
      font-weight: 700;
    }

    .review-content__sidebar-nickname {
      order: 3;
      font-size: 14px;
      line-height: 16px;
    }

    .review-content__main-product-attributes {
      order: 4;
    }

    .review-content__main-review-text {
      order: 5;
    }

    .review-content__main-photos {
      order: 7;
    }

    .review-content__main-recommend-checkbox {
      order: 7;
    }

    .review-content__sidebar-date-verified {
      order: 9;
      display: flex;
      align-items: center;
      gap: 12px;
      margin-left: -4px;
    }

    .review-content__main-helpful-vote {
      order: 10;
    }
  }

  @media (width <= 390px) {
    .review-content__main-product-attributes {
      font-size: 14px;
      flex-direction: column;
    }

    .review-content__main-product-attribute-delimiter {
      display: none;
    }
  }
`;
