// Authentic Review Component Props
export const PRECONTENT = `
  <p>Customer feedback is important, and we’re committed to providing you with the best possible experience with our products. Our goal is to help you make informed decisions about the products you buy, and we do that by collecting and displaying honest reviews from our customers.</p>
`;

export const CONTENT = `
  <p>We know the trust consumers place in ratings and reviews is tied directly to authenticity. That’s why we use Bazaarvoice, a neutral third party that collects and authenticates genuine product reviews from customers like you. The Bazaarvoice Authentic Reviews Trust Mark is a symbol of a company’s dedication to authentic consumer feedback regarding its products and services. You can read more about Bazaarvoice here (link to more information).</p>
  <p>We’re proud of the quality of our products, and we’re confident that you’ll love them too. Here’s how we ensure transparency and authenticity:</p>
  <ul>
  <li>Unbiased Feedback: We encourage honest and unbiased feedback. Whether positive or negative, all reviews contribute to our continuous improvement efforts. We do not alter or manipulate these reviews.</li>
  <li>Moderation and Authenticity: Bazaarvoice ensures that reviews comply with guidelines and are authentic. They verify the legitimacy of each review to maintain transparency.</li>
  <li>FTC Compliance: Our review collection process adheres to the Federal Trade Commission (FTC) guidelines. We do not incentivize or influence customers to provide favorable reviews.</li>
  </ul>
  <p>We appreciate your trust in Owens Corning. Your reviews help us enhance our products and provide an authentic experience for homeowners and professionals alike.</p>
`;

// Rating Component Props

export const ratingAverageValue = 4.5;

export const ratingStarRating = {
  1: 150,
  2: 340,
  3: 120,
  4: 800,
  5: 1020,
};

const randomInteger = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

//  Review Content Component Props
export const reviewContentInitialProps = () => ({
  ratings: 75,
  reviewTitle: 'Great product ever!',
  posterNickname: 'John Doe Junior McCormack II',
  postersZipCode: '43611',
  reviewDate: '1/23/24',
  verifiedPurchase: true,
  productName: 'Very Pink Shingle',
  productColor: 'Modern pink',
  reviewText:
    'This is the besPoster’s Imagest product I have ever used. I would recommend it to anyone. It is very easy to install and looks great. I have had it for a few months now and it still looks brand new. I would definitely buy this product again.',
  posterImages: [
    `https://via.assets.so/album.png?id=${randomInteger(
      1,
      30
    )}&q=95&w=500&h=500&fit=fill&random=${randomInteger(1, 1000)}`,
    `https://via.assets.so/album.png?id=${randomInteger(
      1,
      30
    )}&q=95&w=500&h=500&fit=fill&random=${randomInteger(1000, 2000)}`,
    `https://via.assets.so/album.png?id=${randomInteger(
      1,
      30
    )}&q=95&w=500&h=500&fit=fill&random=${randomInteger(2000, 3000)}`,
  ],
  bvIsRecommended: false,
  thumbsUp: 3,
  thumbsDown: 0,
});
