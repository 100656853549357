import { css } from '@emotion/react';

export default css`
  font-family: OCRoboto, Roboto, sans-serif;
  font-feature-settings: 'liga' off, 'clig' off;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .photo-viewer-modal__body {
    position: relative;
    height: fit-content;
    background-color: #fffffe;
    box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;

    @media (min-width: 884px) {
      max-width: 884px;
    }

    @media (min-height: 940px) {
      max-height: 940px;
    }
  }

  .photo-viewer-modal__content {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
  }

  .photo-viewer-modal__title {
    font-family: 'OCRoboto', Roboto, sans-serif;
    font-weight: 700;
    text-transform: none;
    margin-bottom: 5px;
  }

  .photo-viewer-modal__attribute-delimiter {
    padding: 0 8px;
  }

  .photo-viewer-modal__text {
    margin: 5px 0;
  }

  .photo-viewer-modal__text--small {
    font-size: 12px;
  }

  .photo-viewer-modal__close-button {
    position: absolute;
    z-index: 1;
    top: 12px;
    right: 16px;
    height: 24px;
    width: 24px;
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
  }
`;
