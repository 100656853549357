import styled from '@emotion/styled';

import { Mobile } from './internals/Devices';
import { Screens } from '../../PageBuilder/editing/Viewport';
import RichText from './RichText';
import { BasicContent } from './BasicContent';
import Dropdown from '../oc-dropdown';
import createSizes from "./utilities/sizes";

const Sizes = createSizes({
  m: { [Mobile]: 345 },
});

const AccordionWrapper = styled.div`
display: flex;
justify-content: ${ (props) => props.alignment === 'Left' ? 'flex-start' : 'center' };
`;

const AccordionContainer = styled.div`
  margin: 0;
  width: ${({ width }) => `${ width.Mobile}px` };
  @media (min-width: ${ Screens.Tablet }px) { width: ${ ({ width }) => `${ width.Tablet }px`}; }
  @media (min-width: ${ Screens.Desktop }px) { width: ${ ({ width }) => `${ width.Desktop }px`}; }
`;

const CMSAccordion = ({ accordions, copy, containerSize, alignment }) => {
  const size = containerSize?.selection || 'xl';
  const Width = Sizes[size];

  return (
    <AccordionWrapper alignment={ alignment }>
      <AccordionContainer width={ Width } size={ size }>
        <BasicContent { ...copy } />

        {accordions?.map((acc, index) => {
          return (
            <Dropdown key={index} label={ acc?.title }>
              <RichText content={ acc?.content } />
            </Dropdown>
          )
        })}
      </AccordionContainer>
    </AccordionWrapper>
  )
}

export default CMSAccordion;
