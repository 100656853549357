import PropTypes from 'prop-types';

import styles from './styles';
import SelectDropdown from '../../ComponentLibrary/input-elements/oc-select-dropdown';
import Text from '../../OC/oc-text';

export const SHINGLES_OPTIONS = [
  { value: 'TK30', text: 'Duration®' },
  { value: 'HK30', text: 'Oakridge®' },
  { value: 'PM30', text: 'Supreme®' },
];

export const ProductFilter = ({ productSelected, setProductSelected }) => (
  <div css={styles} data-testid="bazaarvoice-product-filter">
    <p className="product-filter__label">
      <Text content="Filter by shingle line" />
    </p>
    <SelectDropdown
      value={productSelected}
      onChange={e => setProductSelected(e.target.value)}
      options={[{ value: '', text: 'All' }, ...SHINGLES_OPTIONS]}
    />
  </div>
);

ProductFilter.propTypes = {
  productSelected: PropTypes.string.isRequired,
  setProductSelected: PropTypes.func.isRequired,
};
