import { css } from '@emotion/react';

export default css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  font-family: OCRoboto, Roboto, sans-serif;
  font-feature-settings: 'liga' off, 'clig' off;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .authentic-review__precontent ul,
  .authentic-review__precontent ol,
  .authentic-review__content ul,
  .authentic-review__content ol {
    padding-left: 30px;
    margin-bottom: 8px;
  }

  .authentic-review__precontent p,
  .authentic-review__precontent div,
  .authentic-review__content p,
  .authentic-review__content div {
    margin-bottom: 8px;
  }

  .authentic-review__header {
    font-size: 40px;
    font-weight: 600;
    line-height: 42px;
    text-transform: uppercase;
    font-family: 'OCOswald', Oswald, 'OCRoboto', Roboto, sans-serif;
  }

  .authentic-review__preheader {
    margin-bottom: 8px;
  }

  .authentic-review__main_header {
    color: #d40f7d;
    margin-bottom: 16px;

    @media (min-width: 300px) {
      text-wrap: nowrap;
    }
  }

  .authentic-review__precontent {
    font-weight: 400;
  }

  .authentic-review__content {
    font-weight: 400;
  }

  .authentic-review__toggle-switcher {
    line-height: 22px;
    text-transform: capitalize;
  }

  .authentic-review__toggle-switcher__more,
  .authentic-review__toggle-switcher__less {
    cursor: pointer;
  }

  .authentic-review__toggle-switcher__img {
    margin-left: 16px;
  }

  @media (width <= 768px) {
    .authentic-review__header {
      font-size: 26px;
      line-height: 26px;
    }

    .authentic-review__preheader,
    .authentic-review__main_header {
      margin-bottom: 0;
    }

    .authentic-review__precontent,
    .authentic-review__content {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;
