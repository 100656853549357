import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import Theme from '../../../themes/index';
import PropTypes from "prop-types";

const colorIsWhite = (color) => {
  return (color.toLowerCase() == "#ffffff") || (color.toLowerCase() == "#fff") || (color.toLowerCase() == "white");
}

const buttonStyles = (props) => css`
  align-items: center;
  background: ${props.color};
  border: 1px solid ${colorIsWhite(props.color) ? 'black' : props.color};
  color: ${colorIsWhite(props.color) ? 'black' : 'white'};
  cursor: pointer;
  display: inline-flex;
  font-family: ${Theme.fonts.body}, sans-serif;
  font-size: 18px;
  font-weight: 400;
  justify-content: center;
  min-height: 35px;
  min-width: 120px;
  overflow: hidden;
  padding: 0 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: ${props.textTransform || 'none'};
  transition: 0.25s ease-in-out;
  z-index: 1;

  // Prevent transition on touch devices
  @media (hover: none), (-moz-touch-enabled: 1) {
    transition: none;
  }

  &:before {
    background-color: ${colorIsWhite(props.color) ? 'black' : 'white'};
    bottom: 0;
    content: '';
    left: -1%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    transition: 0.25s ease-in-out;
    z-index: -1;

    /* Prevent transition on touch devices*/
    @media (hover: none), (-moz-touch-enabled: 1) {
      transition: none;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${props.color};
    outline: 0;

    &:before {
      /* display: block; */
      right: 0;
      transform: translateX(0);
      transition: 0.25s ease-in-out;

      /* Prevent transition on touch devices */
      @media (hover: none), (-moz-touch-enabled: 1) {
        transition: none;
      }
    }
  }
`;

const disabledStyle = css`
  opacity: 0.5;
  pointer-events: none;

  &:before {
    transition: none;
  }
`;

const Button = styled.button`
  ${buttonStyles}
  ${props => props.disabled ? disabledStyle : null}
`;

const FormButton = ({ buttonType="button", children, color=Theme.colors.brand, disabled=false, onClick, ...props }) => {
  return(
    <Button
      color={color}
      disabled={disabled}
      onClick={onClick}
      type={buttonType}
      {...props}
    >
      {children}
    </Button>
  );
};

export default FormButton;

FormButton.propTypes = {
  /* HTML: Sets the button's "type" attribute; defaults to "button" */
  buttonType: PropTypes.string,
  /* HTML: Sets the button text & content */
  children: PropTypes.any,
  /* CSS: Hex value for color theme */
  color: PropTypes.string,
  /* HTML: Sets the button's disabled attribute; defaults to false */
  disabled: PropTypes.bool,
  /* JSX: Determines the onClick functionality & behavior */
  // onClick:
};
