import React from 'react';

import Styles from './styles';

// eslint-disable-next-line react/prop-types
const MoreReviewContentButton = ({ onClickHandler }) => (
  <div css={Styles}>
    <button
      type="button"
      className="more-review-content-button"
      onClick={onClickHandler}
    >
      See More Reviews
    </button>
  </div>
);

export { MoreReviewContentButton };
