import PropTypes from 'prop-types';

import styles from './styles';
import SelectDropdown from '../../ComponentLibrary/input-elements/oc-select-dropdown';
import Text from '../../OC/oc-text';

const OPTIONS = [
  { value: 'latest', text: 'Latest' },
  { value: 'highest', text: 'Highest rating' },
  { value: 'lowest', text: 'Lowest rating' },
];

export const SortBy = ({ sort = 'latest', setSort }) => (
  <div css={styles} data-testid="bazaarvoice-sort-by">
    <p className="sort-by__label">
      <Text content="Sort By" />
    </p>
    <SelectDropdown
      value={sort}
      onChange={e => setSort(e.target.value)}
      options={OPTIONS}
    />
  </div>
);

SortBy.propTypes = {
  // eslint-disable-next-line react/require-default-props
  sort: PropTypes.string,
  setSort: PropTypes.func.isRequired,
};
