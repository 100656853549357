import { css } from '@emotion/react';

export default css`
  font-family: OCRoboto, Roboto, sans-serif;
  font-feature-settings: 'liga' off, 'clig' off;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .rating-grid {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 5px;
  }

  .rating-grid_row {
    display: contents;
  }

  .rating-grid_cell {
    padding: 0 5px 0 0px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  .rating-line {
    background-color: #d1d1d1;
    height: 10px;
    width: 100%;
  }

  .rating-line_filler {
    background-color: #d40f7d;
    height: 10px;
    width: 0;
  }
`;
