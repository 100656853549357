import React, { useState } from 'react';
import { jsx, css } from '@emotion/react';
import { IFramedForm } from '../../IFramedForm';
import { SchemaForm } from '../../forms/InnerSchemaForm';
import CTAButton from '../../CTAButton';
import Theme from '../../../themes';
import { connectLocation } from '../../location/LocationConnectors';
import querystring from 'qs';
import { canUseDOM } from '../../helpers/dom';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import SchemaTransformer from './Form/SchemaTransformer';
import createSizes from "./utilities/sizes";
import styled from "@emotion/styled";
import { Screens } from "../../PageBuilder/editing/Viewport";

const Sizes = createSizes();

const FormWrapper = styled.div`
display: flex;
justify-content: ${ (props) => props.alignment === 'Left' ? 'flex-start' : 'center' };
`;

const FormContainer = styled.div`
  margin: 0;
  width: ${({ width }) => `${ width.Mobile}px` };
  @media (min-width: ${ Screens.Tablet }px) { width: ${ ({ width }) => `${ width.Tablet }px`}; }
  @media (min-width: ${ Screens.Desktop }px) { width: ${ ({ width }) => `${ width.Desktop }px`}; }
`;

export default connectLocation(({t, locale: { code }, containerSettings, form_uid, form_url, success_content = {}, content, sandbox=false, test=false, success, ...props }) => {
  const [transformedSchemas, setTransformedSchemas] = useState();
  const { size, alignment } = containerSettings;
  const Width = Sizes[(size || 'xl')];
  useDeepCompareEffectNoCheck(() => {
    if (content) {
      new SchemaTransformer(t, content.schema?.form, content.schema?.ui).transformed().then(values => {
          setTransformedSchemas(values)
        }
      )
    }
  }, [content?.schema]);

  if (!form_uid && !content)
    return <></>;
    const { path } = props;
  const params = {
    ...( canUseDOM() ? querystring.parse(document.location.search.slice(1)) : {} ),
    initialWidth: 0,
    parentTitle: canUseDOM() ? document.title : '',
    parentUrl: canUseDOM() ? document.location.href : '',
    styling: 'oc-2020',
    lang: code,
    path: path?.join('.'),
    ...(test ? { test: true } : {}),
  }

  const query = `?${querystring.stringify(params)}`;

  if (form_url) {
    return (
      <IFramedForm scrollOnSuccess key={ form_uid } src={ `${ MDMS_URL }${form_url}${ query }` }/>
    );
  } else {

    const cssThemeOverrides = css`
      background: ${Theme.colors.brand};
      border: 1px solid ${Theme.colors.brand};
      font-family: ${Theme.fonts.body};

      &:hover,
      &:focus {
        color: ${Theme.colors.brand};
      }
    `;

    const additionalProps = {};
    if (content && transformedSchemas) {
      if (typeof(Board) !== 'undefined') {
        additionalProps.uid = [Board.build.site, Board.build.language.toLowerCase(), Board.build.path].join('/');
      }
      additionalProps.schema = transformedSchemas.schema;
      additionalProps.uiSchema = transformedSchemas.uiSchema;
      additionalProps.successContent = content.success_content;
    }

    return (
      <FormWrapper alignment={ alignment }>
        <FormContainer width={ Width } size={ size }>
          <SchemaForm key={ form_uid }
                      uid={ form_uid }
                      search={ query }
                      success_content={ success_content }
                      UI={ UI }
                      sandbox={ sandbox }
                      test={ test }
                      success={ success }
                      { ...props }
                      { ...additionalProps }>
            <CTAButton className="oc-cta-button" css={cssThemeOverrides} element="button" text={t('submit')} type="submit" />
          </SchemaForm>
        </FormContainer>
      </FormWrapper>
    )
  }
});
